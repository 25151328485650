import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconDevices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.711 23.25h5.365c1.7 0 3-1.3 3-3V3.75c0-1.7-1.3-3-3-3h-9.5c-1.7 0-3 1.3-3 3v1.703"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.357 21.23c0 1.145-.875 2.02-2.02 2.02H2.944c-1.144 0-2.02-.875-2.02-2.02V10.124c0-1.144.876-2.019 2.02-2.019h6.395c1.144 0 2.019.875 2.019 2.02zm3.272-2.48h8.447M.924 20.221h10.433"
    />
  </svg>
);
export const IconDevices = memo(SvgIconDevices);
