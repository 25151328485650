import { StyledSideNavSubMenu, StyledSideNavSubMenuWrapper } from './SideNavSubMenu.styles';
import { MenuItem, NavigateBack } from '../utils/utilComponents';
import { SideNavSubMenuProps } from '../Navbar.types';
import { NavbarQuickLinks } from '../NavBarQuickLinks/NavBarQuickLinks';
import { useMemo, useState } from 'react';
import { CategoryItem } from '../../../types/navData.types';

type ActiveMenuItem = {
  parent: CategoryItem | null;
  items: CategoryItem[];
};

export const SideNavSubMenu = ({
  items,
  accountHomeUrl,
  productsUrl,
  LinkComponent,
  setSideNavOpen,
  loggedIn,
}: SideNavSubMenuProps) => {
  /**
   * Store the currently active sub menus which are open
   *
   * - If an empty array, the user is at the top level of the menu
   * - If the value is `["Phones"]`, then the user is in the Phones sub menu
   * - If the value is `["Phones", "Apple"]`, then the user is in the Phones -> Apple sub menu
   */
  const [activeMenus, setActiveMenus] = useState<string[]>([]);

  const handleTraverseNav = (direction: 'up' | 'down', name: string) => {
    if (direction === 'up') {
      setActiveMenus((prevState) => {
        return prevState.filter((item) => item !== name);
      });
    } else {
      setActiveMenus((prevState) => {
        return [...prevState, name];
      });
    }
  };

  /**
   * Using the `activeMenus` array, generate an array of menus.
   *
   * The first item in the array is the top level menu, and each subsequent item is a sub menu.
   *
   * Include the parent item in the array to allow for the "See all" link to be displayed, and also
   * other info like the back button.
   */
  const activeMenuItems = useMemo(() => {
    return activeMenus.reduce(
      (acc, curr) => {
        const lastGroup = acc[acc.length - 1];
        if (lastGroup) {
          const item = lastGroup.items.find((item) => item.name === curr);
          if (item?.subItems) {
            return [
              ...acc,
              {
                parent: item,
                items: item.subItems,
              },
            ];
          }
        }

        return acc;
      },
      [
        {
          parent: null,
          items: items.categories,
        } as ActiveMenuItem,
      ],
    );
  }, [activeMenus, items.categories]);

  return (
    <StyledSideNavSubMenuWrapper $activeItemsLength={activeMenuItems.length}>
      {activeMenuItems.map((group, groupIndex) => {
        const parent = group.parent;
        const isFirstGroup = groupIndex === 0;

        /**
         * - When `activeMenus` is `[]`, `subMenuTestId` = `categories`
         * - When `activeMenus` is `["Phones"]`, `subMenuTestId` = `categories-Phones`
         * - When `activeMenus` is `["Phones", "Apple"]`, `subMenuTestId` = `categories-Phones-Apple`
         */
        const subMenuTestId = ['categories', ...activeMenus.slice(0, groupIndex)].join('-');

        return (
          <StyledSideNavSubMenu
            key={parent?.name ?? 'categories'}
            data-testid={`submenu-${subMenuTestId}`}
            $active={activeMenus.length === groupIndex}
            tabIndex={activeMenus.length !== groupIndex ? -1 : undefined}
          >
            {parent && (
              <>
                <NavigateBack
                  onClick={() => handleTraverseNav('up', parent.name)}
                  title={[...activeMenus].reverse().join(' ')}
                  titleLink={`${productsUrl}${parent.url}`}
                />
                <MenuItem
                  dataTestId={`submenu-see-all-${subMenuTestId}`}
                  isBold
                  border
                  label={`See all ${[...activeMenus].reverse().join(' ')}`}
                  onClick={parent.url}
                  productsUrl={productsUrl}
                  LinkComponent={LinkComponent}
                />
              </>
            )}
            {group.items.map((item) => {
              const hasSubItems = item.subItems && item.subItems.length > 0;
              return (
                <MenuItem
                  key={item.name}
                  dataTestId={`submenu-item-${subMenuTestId}-${item.name}`}
                  onClick={hasSubItems ? () => handleTraverseNav('down', item.name) : item.url}
                  label={item.label ?? item.name}
                  icon={isFirstGroup ? item.name.toLowerCase() : undefined}
                  isBold={isFirstGroup}
                  productsUrl={productsUrl}
                  LinkComponent={LinkComponent}
                />
              );
            })}
            {isFirstGroup && (
              <NavbarQuickLinks
                loggedIn={loggedIn}
                accountHomeUrl={accountHomeUrl}
                setSideNavOpen={setSideNavOpen}
              />
            )}
          </StyledSideNavSubMenu>
        );
      })}
    </StyledSideNavSubMenuWrapper>
  );
};
