import { IconLogoApple } from 'uibook-icons/custom/IconLogoApple';
import { IconLogoGoogle } from 'uibook-icons/custom/IconLogoGoogle';
import { IconLogoLenovo } from 'uibook-icons/custom/IconLogoLenovo';
import { IconLogoPlaystation } from 'uibook-icons/custom/IconLogoPlaystation';
import { IconLogoSamsung } from 'uibook-icons/custom/IconLogoSamsung';
import { IconLogoXbox } from 'uibook-icons/custom/IconLogoXbox';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { Typography } from '../../foundations/Typography/Typography';
import { Button } from '../Button/Button';
import { useGlobalSearchContext } from './providers/GlobalSearchContext';
import { typesenseProductsFields } from '../../utils/search';
import { cn } from '../../utils/cn';
import { getReactImageProps, imgixLoader } from '../../utils/images';
import { ASSETS_IMAGES_BASE_URL, TAILWIND_BREAKPOINTS } from '../../constants';

export const GLOBAL_SEARCH_BRANDS = [
  {
    make: 'Apple',
    icon: IconLogoApple,
    className: 'w-[32%]',
  },
  {
    make: 'Sony',
    icon: IconLogoPlaystation,
    className: 'w-[37%]',
  },
  {
    make: 'Microsoft',
    icon: IconLogoXbox,
    className: 'w-[37%]',
  },
  {
    make: 'Samsung',
    icon: IconLogoSamsung,
    className: 'w-[81%]',
  },
  {
    make: 'Google',
    icon: IconLogoGoogle,
    className: 'w-[81%]',
  },
  {
    make: 'Lenovo',
    icon: IconLogoLenovo,
    className: 'w-[81%]',
  },
] as const;

type GlobalSearchBrandsBackgroundImageProps = {
  make: string;
};

const GlobalSearchBrandsBackgroundImage = ({ make }: GlobalSearchBrandsBackgroundImageProps) => {
  const src = new URL(`${ASSETS_IMAGES_BASE_URL}/search-overlay-make-${make.toLowerCase()}-v1.png`);

  /**
   * Apply the following transforms to the image
   *
   * @url https://docs.imgix.com/apis/url
   */
  src.searchParams.set('fm', 'avif');

  const desktopWidth = 98;
  const mobileWidth = 108;

  const imageProps = getReactImageProps({
    alt: `Image of a product by ${make}`,
    quality: 50,
    width: mobileWidth,
    /** Fine to use `mobileWidth` for the `height` here, as the images are square */
    height: mobileWidth,
    src: src.toString(),
    sizes: `(max-width: ${TAILWIND_BREAKPOINTS.lg - 1}px) calc((100vw-88px)/3), 98px`,
  }).props;

  const widthWithDouble = (val: number) => [val, val * 2];
  /**
   * Convert `desktopWidth` and `mobileWidth` values to an array of:
   *
   * ```ts
   * [desktopWidth, desktopWidth * 2, mobileWidth, mobileWidth * 2];
   * ```
   *
   * Then, sort them so it's lowest to highest, and map them to a string of:
   *
   * - `src` with the `width` and `quality` params
   * - `width` as a string
   */
  const srcSet = [
    ...[desktopWidth].flatMap(widthWithDouble),
    ...[mobileWidth].flatMap(widthWithDouble),
  ]
    .sort((a, b) => a - b)
    .map((size) =>
      [imgixLoader({ src: src.toString(), width: size, quality: 50 }), `${size}w`].join(' '),
    )
    .join(', ');

  return <img {...imageProps} srcSet={srcSet} className="absolute size-full" />;
};

export const GlobalSearchBrands = () => {
  const { LinkComponent, productsUrl } = useNavBarContext();
  const { handleCloseSearchOverlay } = useGlobalSearchContext();

  const Link = LinkComponent || 'a';

  const productsBaseUrl = `${productsUrl}/products`;

  return (
    <div className="flex flex-col gap-4" data-testid="GlobalSearchBrands">
      <div className="flex items-center justify-between gap-4">
        <Typography bold>Brands</Typography>
        <Button
          as={Link}
          variant="text"
          href={productsBaseUrl}
          onClick={() => handleCloseSearchOverlay()}
          data-testid="GlobalSearchBrands-ViewAll"
        >
          View all
        </Button>
      </div>
      {/* `.max-h-none` is added here to override styles in WebFlow */}
      <div className="grid max-h-none grid-cols-3 gap-5">
        {GLOBAL_SEARCH_BRANDS.map((brand) => (
          <Link
            key={brand.make}
            className="relative flex aspect-square items-center justify-center overflow-hidden rounded bg-blue-500"
            title={`View all ${brand.make} products`}
            href={`${productsBaseUrl}?${typesenseProductsFields.make}=${brand.make}`}
            onClick={() => handleCloseSearchOverlay()}
            data-testid={`GlobalSearchBrands-${brand.make}`}
          >
            <GlobalSearchBrandsBackgroundImage make={brand.make} />
            <brand.icon className={cn('relative z-10 shrink-0 text-white', brand.className)} />
          </Link>
        ))}
      </div>
    </div>
  );
};
