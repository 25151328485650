import type { NavDataModel } from '../../../types/navData.types';
import { NavLink, BurgerNav } from '../utils/utilComponents';
import { StyledDesktopItems } from '../NavBarWrapper/NavBarWrapper.styles';
import { Dispatch, SetStateAction } from 'react';
import { SideNavType } from '../NavBarContext/NavBarContext';
import RayloModal from '../../../designSystem/RayloModal';
import { Typography } from '../../../foundations/Typography/Typography';
import { categoryForUrl } from '../utils/utils';
import { handleUpgradeExitModalOpenProps } from '../Navbar.types';
import { useMerchantTheme } from '../../MerchantTheme/MerchantTheme';

type DesktopLinksProps = {
  navData: NavDataModel;
  LinkComponent?: React.ElementType;
  sideNavOpen: SideNavType;
  upgradeExitModalOpen: boolean;
  setUpgradeExitModalOpen: Dispatch<SetStateAction<boolean>>;
  handleCloseSideNav: () => void;
  exitUpgrade: () => void;
  handleUpgradeExitModalOpen: (action: handleUpgradeExitModalOpenProps) => void;
  productsUrl: string;
  displayUpgradeModal: boolean;
  setSideNavOpen: Dispatch<SetStateAction<SideNavType>>;
};

export const DesktopLinks = ({
  navData,
  LinkComponent,
  setUpgradeExitModalOpen,
  handleCloseSideNav,
  upgradeExitModalOpen,
  sideNavOpen,
  exitUpgrade,
  handleUpgradeExitModalOpen,
  productsUrl,
  displayUpgradeModal,
  setSideNavOpen,
}: DesktopLinksProps) => {
  const { themeHasFeature } = useMerchantTheme();
  const handleCloseModal = () => {
    setUpgradeExitModalOpen(false);
    if (sideNavOpen === 'account') {
      handleCloseSideNav();
    }
  };
  return (
    <>
      {upgradeExitModalOpen && (
        <RayloModal
          modalOpen
          dataTestId="upgrade-exit-modal"
          title="Exit your Upgrade?"
          primaryButtonText="Exit"
          primaryButtonOnClick={exitUpgrade}
          secondaryButtonText="Continue"
          handleCloseModal={handleCloseModal}
          secondaryButtonOnClick={handleCloseModal}
        >
          <Typography bold className="mb-4">
            Any upgrade selections will be lost.
          </Typography>
          <Typography>You can restart your upgrade at any time.</Typography>
        </RayloModal>
      )}
      <StyledDesktopItems>
        <div
          data-testid="burger-desktop"
          className="burger-desktop"
          onClick={() =>
            displayUpgradeModal
              ? handleUpgradeExitModalOpen({ category: 'burger-nav' })
              : setSideNavOpen('product')
          }
        >
          <BurgerNav sideNavOpen={sideNavOpen} />
          <p>Menu</p>
        </div>
        <div className="divider" />
        <div className="category-links-desktop">
          {navData.categories.map(({ name, label }) => (
            <NavLink
              dataTestId={`desktop-links-${name}`}
              handleUpgradeExitModal={displayUpgradeModal ? handleUpgradeExitModalOpen : undefined}
              href={`${productsUrl}${categoryForUrl(name.toLowerCase())}`}
              title={label ?? name}
              LinkComponent={LinkComponent}
              key={name}
            />
          ))}
          {themeHasFeature('homepage') && (
            <NavLink
              dataTestId="desktop-links-how-it-works"
              href="/"
              title="How it works"
              LinkComponent={LinkComponent}
            />
          )}
        </div>
      </StyledDesktopItems>
    </>
  );
};
