import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconDesktopComputer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 1.498h16.5s1.5 0 1.5 1.5v10.5s0 1.5-1.5 1.5H3.75s-1.5 0-1.5-1.5v-10.5s0-1.5 1.5-1.5m17.017 21.568a.748.748 0 0 1-.728.932H3.96a.75.75 0 0 1-.728-.932l1.125-4.5a.75.75 0 0 1 .728-.568h13.828a.75.75 0 0 1 .728.568zM2.25 11.998h19.5m-15 9h1.5m7.5 0h1.5m-6 0h1.5"
    />
  </svg>
);
export const IconDesktopComputer = memo(SvgIconDesktopComputer);
