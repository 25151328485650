import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconHeadphones2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.174 21.942s2.837-5.241 2.82-9.992a9.994 9.994 0 0 0-19.988.072c.017 4.753 2.892 9.97 2.892 9.97"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m6.747 14.037-1.433.604a.89.89 0 0 0-.473 1.164L7 20.92a.89.89 0 0 0 1.163.473l1.433-.604a.89.89 0 0 0 .473-1.164L7.91 14.51a.89.89 0 0 0-1.163-.473m7.657 6.753 1.433.605A.89.89 0 0 0 17 20.92l2.159-5.115a.89.89 0 0 0-.473-1.164l-1.433-.604a.89.89 0 0 0-1.163.473l-2.159 5.115a.89.89 0 0 0 .473 1.164Z"
    />
  </svg>
);
export const IconHeadphones2 = memo(SvgIconHeadphones2);
