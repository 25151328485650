import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconBundle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 7.314H3a1.5 1.5 0 0 0-1.5 1.5v13.5a1.5 1.5 0 0 0 1.5 1.5h18a1.5 1.5 0 0 0 1.5-1.5v-13.5a1.5 1.5 0 0 0-1.5-1.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m22.3 8.064-2.4-5.86a1.5 1.5 0 0 0-1.374-.89H5.475a1.5 1.5 0 0 0-1.375.89l-2.4 5.86m10.3-.75v-6"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M18.468 17.843c.216.757-.325 1.513-1.081 1.73-.54.107-.973 0-1.405-.325l-1.296-1.513H9.283l-1.297 1.513c-.324.324-.864.54-1.404.324-.757-.216-1.189-.972-1.08-1.729l1.08-4.646a2.21 2.21 0 0 1 2.16-1.729h6.592c1.08 0 1.945.757 2.161 1.73z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M14.253 15.034a.216.216 0 0 1 0-.432m0 .432a.216.216 0 1 0 0-.432m-4.322.432a.216.216 0 1 1 0-.432m0 .432a.216.216 0 1 0 0-.432"
    />
  </svg>
);
export const IconBundle = memo(SvgIconBundle);
