'use client';

import React, { useEffect, useState } from 'react';
import { StyledTextAccordion, StyledTextAccordionItem } from './TextAccordion.styles';
import { ITextAccordion } from './TextAccordion.types';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import PlusMinusIcon from '../PlusMinusIcon';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

export const TextAccordion = ({
  dataTestId,
  data,
  primaryColour,
  title,
  footerContent,
  headerPadding,
  showDivider,
  boldHeaders,
  defaultOpen,
  forceOpen,
  headerBackgroundHoverColor,
  iconSize,
  isSubMenu,
  iconPaddingLeft,
  iconWidth,
}: ITextAccordion) => {
  const [activeItem, setActiveItem] = useState<number | null>(defaultOpen ?? null);

  useEffect(() => {
    if (typeof forceOpen === 'number') {
      setActiveItem(forceOpen);
    } else {
      setActiveItem(null);
    }
  }, [forceOpen]);

  return (
    <StyledTextAccordion>
      <Container flexRow={false}>
        {title && (
          <Copy
            dataTestId={`${dataTestId}-title`}
            lineHeight={20}
            color={palette.charcoal[500]}
            fontSize={16}
            bold
            styles={{ padding: headerPadding ?? '36px 0 24px 0' }}
          >
            {title}
          </Copy>
        )}
        <ul>
          {data.map(({ title, body }, i) => (
            <StyledTextAccordionItem
              $isSubMenu={isSubMenu}
              $headerBackgroundHoverColor={headerBackgroundHoverColor}
              $showDivider={showDivider}
              active={activeItem === i}
              data-testid={`${dataTestId}-item-${i}`}
              key={`accordion-item-${i}`}
              onClick={() => setActiveItem((current) => (current !== i ? i : null))}
            >
              <div className="item-header">
                <Copy
                  bold={boldHeaders}
                  fontSize={16}
                  lineHeight={20}
                  color={primaryColour}
                  styles={{ marginRight: px2Rem(12) }}
                >
                  {title}
                </Copy>
                <PlusMinusIcon
                  paddingLeft={iconPaddingLeft}
                  color={primaryColour ?? 'black'}
                  isMinus={activeItem === i}
                  size={iconSize}
                  strokeWidth={iconWidth}
                />
              </div>
              <div className="item-body" data-testid={`${dataTestId}-body-${i}`}>
                <div style={{ overflow: 'hidden' }}>{body}</div>
              </div>
            </StyledTextAccordionItem>
          ))}
        </ul>
        {footerContent && (
          <Copy
            styles={{ paddingTop: px2Rem(10) }}
            color={palette.charcoal[400]}
            fontSize={12}
            lineHeight={14}
            dataTestId={`${dataTestId}-footer`}
          >
            {footerContent}
          </Copy>
        )}
      </Container>
    </StyledTextAccordion>
  );
};
