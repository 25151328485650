import { AccountStatusType, Link } from '../Navbar.types';

/** Breakpoint keys from the `NAVBAR_HEIGHTS` */
export type NavbarHeightsBreakpoints = keyof (typeof NAVBAR_HEIGHTS)[keyof typeof NAVBAR_HEIGHTS];

export const BREADCRUMBS_HEIGHT = 44;

/**
 * Static heights of the entire nav, used to make the whole nav slide up with the breadcrumbs
 * smoothly
 */
export const NAVBAR_HEIGHTS = {
  withCalloutCarousel: {
    xs: 208,
    sm: 188,
    lg: 190,
  },
  withoutCalloutCarousel: {
    xs: 160,
    sm: 154,
    lg: 154,
  },
};

export const aboutSubMenuLinks: Link[] = [
  {
    displayName: 'About us',
    link: 'https://www.raylo.com/about',
  },
  {
    displayName: 'Blog',
    link: 'https://www.raylo.com/blog',
  },
  {
    displayName: 'Sustainability',
    link: 'https://www.raylo.com/sustainability',
  },
  {
    displayName: 'Careers',
    link: 'https://www.raylo.com/careers',
  },
];

export const helpSubMenuLinks: Link[] = [
  {
    displayName: 'Help centre',
    link: 'https://help.raylo.com/',
  },
  {
    displayName: 'Contact us',
    link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
  },
];

export const categoryForUrl = (category: string) => {
  return `/products?category=${category.toLowerCase()}`;
};

export const loggedInMenuItems = (
  activeAccount: string | null,
  accountStatus?: AccountStatusType,
) => {
  if (!activeAccount) return [];
  return [
    {
      icon: 'my-tech',
      name: 'My tech',
      link: `/account/overview/${activeAccount}`,
      badge: accountStatus,
    },
    {
      icon: 'account-details',
      name: 'Account details',
      link: `/account/${activeAccount}/your-account`,
    },
    {
      icon: 'referrals',
      name: 'Referrals',
      link: `/account/${activeAccount}/refer-a-friend`,
    },
  ];
};
