import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconTv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.75 2.625h20.5s1 0 1 1v13s0 1-1 1H1.75s-1 0-1-1v-13s0-1 1-1m2 15-2.25 3.75m18.75-3.75 2.25 3.75"
    />
  </svg>
);
export const IconTv = memo(SvgIconTv);
