import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLogoXbox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 42 42" {...props}>
    <path
      fill="currentColor"
      d="M18.976 41.227c-3.126-.3-6.29-1.422-9.009-3.195-2.278-1.487-2.792-2.097-2.792-3.317 0-2.449 2.693-6.739 7.3-11.629 2.617-2.777 6.262-6.033 6.656-5.945.766.172 6.893 6.148 9.186 8.96 3.627 4.45 5.294 8.092 4.447 9.715-.644 1.235-4.64 3.647-7.575 4.574-2.419.764-5.596 1.088-8.213.837M4.098 32.17c-1.893-2.904-2.85-5.763-3.311-9.898-.152-1.366-.098-2.147.346-4.95.554-3.493 2.543-7.534 4.933-10.02 1.018-1.06 1.109-1.085 2.35-.667 1.507.507 3.116 1.619 5.612 3.875l1.456 1.317-.795.977C10.998 17.338 7.1 23.765 5.633 27.742c-.799 2.162-1.12 4.332-.777 5.235.232.61.019.383-.758-.808Zm33.228.494c.187-.913-.05-2.59-.604-4.28-1.2-3.663-5.213-10.476-8.898-15.108l-1.16-1.458 1.255-1.152c1.638-1.505 2.776-2.406 4.003-3.17.97-.604 2.353-1.139 2.948-1.139.367 0 1.659 1.34 2.701 2.803 1.615 2.265 2.803 5.011 3.404 7.87.39 1.847.422 5.8.063 7.643-.294 1.512-.916 3.474-1.522 4.804-.454.997-1.584 2.932-2.079 3.562-.254.324-.255.324-.111-.375M19.31 5.665c-1.7-.864-4.323-1.79-5.772-2.04a10.6 10.6 0 0 0-1.925-.108c-1.196.06-1.143-.002.775-.908 1.594-.754 2.924-1.197 4.73-1.576 2.03-.426 5.848-.431 7.846-.01 2.159.455 4.7 1.4 6.133 2.282l.425.262-.976-.05c-1.941-.097-4.77.687-7.806 2.165-.916.446-1.713.802-1.771.79-.058-.01-.805-.373-1.66-.807Z"
    />
  </svg>
);
export const IconLogoXbox = memo(SvgIconLogoXbox);
