'use client';

import { getCookie, setCookie, deleteCookie, getCookies } from 'cookies-next/client';
import {
  CookieMethod,
  deleteDomainCookie,
  DomainCookieMethod,
  DomainCookieMethodParams,
  filterAllCookiesToDomainCookies,
  getDomainCookie,
  getDomainCookies,
  RayloDomainCookieNamesList,
  setDomainCookie,
} from './cookies.internal';
import { AuthTokenInfo } from '../auth/authTokenInfo';

/**
 * Creates a client domain cookie method by combining a domain cookie method with a cookie store
 * method.
 *
 * @template T - The type of the cookie method.
 * @param domainCookieMethod - The domain cookie method to be used.
 * @param cookieStoreMethod - The cookie store method to be used.
 * @returns A function that takes the parameters of the domain cookie method and returns the result
 *   of the cookie store method.
 */
const createClientDomainCookieMethod = <T extends CookieMethod>(
  domainCookieMethod: DomainCookieMethod<T>,
  cookieStoreMethod: T,
): ((...params: DomainCookieMethodParams<T>) => ReturnType<T>) => {
  return (...params: DomainCookieMethodParams<T>) => {
    return domainCookieMethod(cookieStoreMethod, ...params);
  };
};

export const getClientDomainCookie = createClientDomainCookieMethod(getDomainCookie, getCookie);
export const setClientDomainCookie = createClientDomainCookieMethod(setDomainCookie, setCookie);
export const deleteClientDomainCookie = createClientDomainCookieMethod(
  deleteDomainCookie,
  deleteCookie,
);

export const getClientDomainCookies = () => {
  const allCookies = getDomainCookies(getCookies) as RayloDomainCookieNamesList;
  return filterAllCookiesToDomainCookies(allCookies);
};

export const getClientUserToken = () => {
  const userToken = getDomainCookie(getCookie, 'userToken');
  const userTokenExpiresAt = getDomainCookie(getCookie, 'userTokenExpiresAt');
  return new AuthTokenInfo(userToken, userTokenExpiresAt);
};
