'use client';

import { StyledRayloModal } from './RayloModal.styles';
import { IRayloModal } from './RayloModal.types';
import { px2Rem } from '../../utils/px2Rem';
import { Modal } from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { IconClose } from 'uibook-icons/solid/IconClose';
import { Typography } from '../../foundations/Typography/Typography';

export const RayloModal = ({
  dataTestId,
  modalOpen,
  handleCloseModal,
  title,
  copy,
  children,
  primaryButtonText,
  primaryButtonOnClick,
  disablePrimaryButton,
  secondaryButtonText,
  secondaryButtonOnClick,
}: IRayloModal) => {
  return (
    <StyledRayloModal data-testid={dataTestId}>
      <Modal
        modalOpen={!!modalOpen}
        closeModal={handleCloseModal}
        desktopWidth={px2Rem(500)}
        mobileWidth={px2Rem(280)}
        offsetTop={84}
      >
        <div className="flex flex-col bg-white">
          <div className="px-5">
            <IconClose
              data-testid="modalClose"
              onClick={handleCloseModal}
              className="mb-3 ml-auto mt-6 size-4"
            />
            {title && (
              <Typography
                className="border-b-charcoal-200 mb-5 w-full border-b pb-5"
                bold
                variant="h2"
              >
                {title}
              </Typography>
            )}
          </div>
          <div className="px-5">
            {copy?.map((text, i) => (
              <Typography
                key={`modal-copy-${i}`}
                className="pb-4 last-of-type:pb-0"
                variant="body1"
              >
                {text}
              </Typography>
            ))}
            {children}
          </div>
          <div className="mb-6 px-5">
            {primaryButtonText && (
              <Button
                className="mt-9 w-full"
                data-testid={`${dataTestId}-primaryButton`}
                onClick={primaryButtonOnClick}
                disabled={disablePrimaryButton}
              >
                {primaryButtonText}
              </Button>
            )}
            {secondaryButtonText && (
              <Button className="mt-3 w-full" variant="outlined" onClick={secondaryButtonOnClick}>
                {secondaryButtonText}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </StyledRayloModal>
  );
};
