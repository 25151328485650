import constate from 'constate';
import { useCallback, useState } from 'react';
import { useRayloCookiesContext } from './useRayloCookiesContext';

const useRayloIFrame = () => {
  const [preApprovalLimit, setPreApprovalLimit] = useState<number>(0);
  const [isNewHomepage, setIsNewHomepage] = useState<boolean | undefined>(undefined);
  const [recommendationsLoaded, setRecommendationsLoaded] = useState<boolean>(false);
  const [lightBackground, setLightBackground] = useState<boolean | undefined>(undefined);
  const { setDomainCookie } = useRayloCookiesContext();

  const listenForMessageFromParent = useCallback(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL) return;
        if (!event.data) return;
        if (event.data.context === 'customer') {
          setPreApprovalLimit(parseFloat(event.data.limit));
          setDomainCookie('userToken', event.data.token);
          setIsNewHomepage(event.data.newHomepage);
          setLightBackground(!!event.data.lightBackground);
        }
      },
      false,
    );
  }, [setDomainCookie]);

  const sendMessageToParent = useCallback(() => {
    /**
     * Without checking if the recommendations have loaded, this was causing an infinite loop for
     * both recently viewed and recommendations
     */
    if (recommendationsLoaded) return;
    parent?.postMessage(
      'Recommendation component loaded',
      process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL,
    );
    setRecommendationsLoaded(true);
  }, [recommendationsLoaded]);

  return {
    listenForMessageFromParent,
    sendMessageToParent,
    preApprovalLimit,
    isNewHomepage,
    lightBackground,
  };
};

const [RayloIFrameProvider, useRayloIFrameContext] = constate(useRayloIFrame);
export { RayloIFrameProvider, useRayloIFrameContext };
