import { IconSynchronizeArrowClock } from 'uibook-icons/solid/IconSynchronizeArrowClock';
import { Typography } from '../../foundations/Typography/Typography';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { Button } from '../Button/Button';
import { useGlobalSearchContext } from './providers/GlobalSearchContext';

export const GlobalSearchRecentSearches = () => {
  const { LinkComponent } = useNavBarContext();
  const {
    handleCloseSearchOverlay,
    getSearchQueryUrl,
    trackSearchEvent,
    recentSearches,
    clearRecentSearches,
  } = useGlobalSearchContext();

  if (recentSearches.size === 0) {
    return null;
  }

  const handleClickRecentSearch = (searchTerm: string) => {
    trackSearchEvent(searchTerm);
    handleCloseSearchOverlay();
  };

  const Link = LinkComponent || 'a';

  return (
    <div className="flex flex-col gap-4" data-testid="GlobalSearchRecentSearches">
      <div className="flex items-center justify-between gap-4">
        <Typography bold>Recent searches</Typography>
        <Button
          variant="text"
          onClick={() => clearRecentSearches()}
          data-testid="GlobalSearchRecentSearches-Clear"
        >
          Clear
        </Button>
      </div>
      <div className="flex flex-col items-center gap-4">
        {[...recentSearches].map((searchTerm) => (
          <div key={searchTerm} className="flex w-full items-center gap-4">
            <Button
              variant="text"
              color="charcoal"
              as={Link}
              href={getSearchQueryUrl(searchTerm)}
              onClick={() => handleClickRecentSearch(searchTerm)}
              data-testid={`GlobalSearchRecentSearches-${searchTerm}`}
              startIcon={<IconSynchronizeArrowClock className="w-6 shrink-0" />}
            >
              {searchTerm}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
