import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.714 1.011H4.286C3.576 1.011 3 1.668 3 2.478v19.067c0 .81.576 1.466 1.286 1.466h15.428c.71 0 1.286-.656 1.286-1.466V2.478c0-.81-.576-1.467-1.286-1.467"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 20.012a5 5 0 1 0 0-10 5 5 0 0 0 0 10"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 16.012a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0-9.001a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    />
  </svg>
);
export const IconSpeaker = memo(SvgIconSpeaker);
