import constate from 'constate';
import {
  deleteClientDomainCookie,
  getClientDomainCookies,
  setClientDomainCookie,
} from 'core/cookies/cookies.client';

const useRayloCookies = () => {
  const cookieValues = getClientDomainCookies();

  const setDomainCookie = (
    name: Parameters<typeof setClientDomainCookie>[0],
    value: Parameters<typeof setClientDomainCookie>[1],
  ) => {
    setClientDomainCookie(name, value);
  };

  const removeDomainCookie = (name: Parameters<typeof deleteClientDomainCookie>[0]) => {
    deleteClientDomainCookie(name);
  };

  return {
    cookieValues,
    setDomainCookie,
    removeDomainCookie,
  };
};

const [RayloCookiesProvider, useRayloCookiesContext] = constate(useRayloCookies);
export { RayloCookiesProvider, useRayloCookiesContext };
