'use client';

import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../../utils/cn';

enum DrawerStateEnum {
  OPEN = 'open',
  CLOSING = 'closing',
}

/**
 * This should match the `animate-` util classes below, so if this is `300` then the `animate-`
 * classes should be `300ms`.
 *
 * In future, this could be a prop that is passed to the component, but for now it's hard-coded. The
 * value of the prop could be added to the slider & backdrop components as a CSS custom property,
 * and then the `animate-` classes could use that custom property. One for the future, if needed.
 */
export const DRAWER_TRANSITION_DURATION_MS = 300;

const drawerSliderClassNames = cva(
  'shadow-6 fixed top-0 z-[1000] h-dvh w-full overflow-y-auto bg-white lg:w-96',
  {
    variants: {
      /** Set the default left/right */
      anchorMobile: {
        left: 'left-0',
        right: 'right-0',
      },
      /** Set the desktop left/right */
      anchorDesktop: {
        left: 'lg:left-0',
        right: 'lg:right-0',
      },
      drawerState: {
        [DrawerStateEnum.OPEN]: null,
        [DrawerStateEnum.CLOSING]: null,
      },
    },
    compoundVariants: [
      /** Reset the desktop left/right if the default anchor is different to the desktop anchor */
      {
        anchorMobile: 'left',
        anchorDesktop: 'right',
        className: 'lg:left-auto',
      },
      {
        anchorMobile: 'right',
        anchorDesktop: 'left',
        className: 'lg:right-auto',
      },

      /** Apply the default animations based on the mobile anchor */
      {
        anchorMobile: 'left',
        drawerState: DrawerStateEnum.OPEN,
        className: 'animate-[slideInLeft_300ms_ease_1_both]',
      },
      {
        anchorMobile: 'left',
        drawerState: DrawerStateEnum.CLOSING,
        className: 'animate-[slideOutLeft_300ms_ease_1_both]',
      },
      {
        anchorMobile: 'right',
        drawerState: DrawerStateEnum.OPEN,
        className: 'animate-[slideInRight_300ms_ease_1_both]',
      },
      {
        anchorMobile: 'right',
        drawerState: DrawerStateEnum.CLOSING,
        className: 'animate-[slideOutRight_300ms_ease_1_both]',
      },

      /** Apply the animations based on the desktop anchor */
      {
        anchorDesktop: 'left',
        drawerState: DrawerStateEnum.OPEN,
        className: 'lg:animate-[slideInLeft_300ms_ease_1_both]',
      },
      {
        anchorDesktop: 'left',
        drawerState: DrawerStateEnum.CLOSING,
        className: 'lg:animate-[slideOutLeft_300ms_ease_1_both]',
      },
      {
        anchorDesktop: 'right',
        drawerState: DrawerStateEnum.OPEN,
        className: 'lg:animate-[slideInRight_300ms_ease_1_both]',
      },
      {
        anchorDesktop: 'right',
        drawerState: DrawerStateEnum.CLOSING,
        className: 'lg:animate-[slideOutRight_300ms_ease_1_both]',
      },
    ],
    defaultVariants: {
      anchorMobile: 'left',
    },
  },
);

type DrawerCvaProps = VariantProps<typeof drawerSliderClassNames>;

type DrawerProps = PropsWithChildren<{
  /**
   * If `true`, the component is shown.
   *
   * @default false
   */
  open: boolean;
  /** Callback fired when the component requests to be closed. */
  onClose: (event: KeyboardEvent | React.MouseEvent) => void;
  /**
   * Side from which the drawer will appear.
   *
   * @default 'left'
   */
  anchorMobile?: DrawerCvaProps['anchorMobile'];
  /**
   * Side from which the drawer will appear at the `lg` breakpoint, if it should differ from the
   * mobile breakpoint.
   */
  anchorDesktop?: DrawerCvaProps['anchorDesktop'];
  /** Props to pass to the backdrop element. */
  backdropProps?: React.ComponentPropsWithoutRef<'div'>;
  /** Props to pass to the backdrop element. */
  sliderProps?: React.ComponentPropsWithoutRef<'div'>;
}>;

/**
 * Drawer component, inspired by https://mui.com/material-ui/react-drawer/
 *
 * This is a controlled component, so the `open` prop should be controlled by the parent component.
 *
 * Example:
 *
 * ```tsx
 * const MyComponent = () => {
 *   const [open, setOpen] = useState(false);
 *
 *   const handleDrawerClose = () => {
 *     setOpen(false);
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={() => setOpen(!open)}>Toggle</button>
 *       <Drawer open={open} onClose={handleDrawerClose}>
 *         <p>Content to go in the Drawer</p>
 *       </Drawer>
 *     </div>
 *   );
 * };
 * ```
 *
 * There is also a `DrawerHeader` component that can be used to add a header to the drawer:
 *
 * ```tsx
 * const MyComponent = () => {
 *   const [open, setOpen] = useState(false);
 *
 *   const handleDrawerClose = () => {
 *     setOpen(false);
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={() => setOpen(!open)}>Toggle</button>
 *       <Drawer open={open} onClose={handleDrawerClose}>
 *         <DrawerHeader
 *           title="Menu"
 *           onClose={handleDrawerClose}
 *           className="bg-blue-500 text-white"
 *         />
 *         <p>Content to go in the Drawer</p>
 *       </Drawer>
 *     </div>
 *   );
 * };
 * ```
 */
export const Drawer = ({
  open,
  anchorMobile,
  anchorDesktop,
  onClose,
  backdropProps,
  sliderProps,
  children,
}: DrawerProps) => {
  const [localIsOpen, setLocalIsOpen] = useState(open);

  /** Prevent scrolling on the body when the drawer is open */
  useEffect(() => {
    const overflowClassName = 'overflow-hidden';
    if (open) {
      document.body.classList.add(overflowClassName);
    } else {
      document.body.classList.remove(overflowClassName);
    }
  }, [open]);

  /**
   * Update the local state when `open` is `true`, as we use this to help us finish the slide-out
   * animation before removing it from the DOM.
   */
  useEffect(() => {
    if (open) {
      setLocalIsOpen(true);
    }
  }, [open]);

  /**
   * Callback from the child component to update the local state after the Drawer has finished the
   * closing animation, so that the this parent component removes the child component from the DOM
   */
  const handleUpdateLocalIsOpenState = (newIsOpen: boolean) => {
    setLocalIsOpen(newIsOpen);
  };

  /** Don't render anything in the closed state */
  if (!open && !localIsOpen) {
    return null;
  }

  return (
    <DrawerInner
      open={open}
      anchorMobile={anchorMobile}
      anchorDesktop={anchorDesktop}
      onClose={onClose}
      backdropProps={backdropProps}
      sliderProps={sliderProps}
      handleUpdateLocalIsOpenState={handleUpdateLocalIsOpenState}
    >
      {children}
    </DrawerInner>
  );
};

type DrawerInnerProps = DrawerProps & {
  handleUpdateLocalIsOpenState: (newIsOpen: boolean) => void;
};

/**
 * Child component of `Drawer`, which is mostly used to handle race conditions where we only want to
 * render the content to the DOM when:
 *
 * 1. The modal is open
 * 2. The modal is closing, but has not finished the closing animation
 *
 * The rest of the time, this component should not be rendered by the parent component.
 *
 * This looks a bit overkill, but for some reason this was the only way to get `autoFocus` working
 * on input elements rendered within the Drawer to work.
 */
function DrawerInner({
  open,
  anchorMobile,
  anchorDesktop,
  onClose,
  backdropProps,
  sliderProps,
  handleUpdateLocalIsOpenState,
  children,
}: DrawerInnerProps) {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [drawerState, setDrawerState] = useState<DrawerStateEnum>(DrawerStateEnum.OPEN);

  /** If the animation timeout is still running when the component is unmounted, clear the timeout */
  useEffect(() => {
    const timerRefCurrent = timerRef.current;
    return () => {
      if (timerRefCurrent) {
        clearTimeout(timerRefCurrent);
      }
    };
  }, []);

  /** Allow the `escape` key to close the drawer */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose(event);
      }
    };

    if (drawerState === DrawerStateEnum.OPEN) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [drawerState, onClose]);

  /**
   * We want to control the rending of the `children` locally within this component, but based on
   * the `open` prop. This is because we want to animate the opening and closing of the drawer, but
   * we want to return `null` after the animation has finished.
   *
   * If the `open` prop is `false` and the `drawerState` is `OPEN`, set the `drawerState` to
   * `CLOSING`, then after the animation is complete, set the `drawerState` to `CLOSED`.
   *
   * It's important to check that `drawerState === DrawerStateEnum.OPEN`, otherwise it'll end up in
   * an infinite loop.
   */
  useEffect(() => {
    if (!open && drawerState === DrawerStateEnum.OPEN) {
      setDrawerState(DrawerStateEnum.CLOSING);

      timerRef.current = setTimeout(() => {
        /**
         * Fire an update to the parent component, which in turn will stop rendering this child
         * component
         */
        handleUpdateLocalIsOpenState(false);
      }, DRAWER_TRANSITION_DURATION_MS);
    }
  }, [drawerState, handleUpdateLocalIsOpenState, open]);

  return (
    <>
      <div
        {...backdropProps}
        role="presentation"
        tabIndex={-1}
        onClick={onClose}
        className={cn(
          'bg-charcoal-500 fixed inset-0 z-[999] hidden bg-opacity-70 lg:block',
          {
            'pointer-events-auto animate-[fadeIn_300ms_ease_1_both]':
              drawerState === DrawerStateEnum.OPEN,
            'pointer-events-none animate-[fadeOut_300ms_ease_1_both]':
              drawerState === DrawerStateEnum.CLOSING,
          },
          backdropProps?.className,
        )}
      />
      <div
        {...sliderProps}
        aria-modal="true"
        role="dialog"
        className={cn(
          drawerSliderClassNames({
            anchorMobile,
            anchorDesktop,
            drawerState: open ? DrawerStateEnum.OPEN : DrawerStateEnum.CLOSING,
            className: sliderProps?.className,
          }),
        )}
      >
        {children}
      </div>
    </>
  );
}
