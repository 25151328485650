import { useEffect, useMemo } from 'react';
import { Highlight, useHits } from 'react-instantsearch';
import { Typography } from '../../foundations/Typography/Typography';
import { IconSearch } from 'uibook-icons/solid/IconSearch';
import { cn } from '../../utils/cn';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { Button } from '../Button/Button';
import { GlobalSearchTrending } from './GlobalSearchTrending';
import { SearchSearchTerm } from '../../types/search.types';
import { GlobalSearchRecentSearches } from './GlobalSearchRecentSearches';
import {
  ProductsSearchResultsState,
  useGlobalSearchContext,
} from './providers/GlobalSearchContext';
import { GlobalSearchBrands } from './GlobalSearchBrands';
import { GlobalSearchCategories } from './GlobalSearchCategories';
import { useMerchantTheme } from '../MerchantTheme/MerchantTheme';

export const GlobalSearchQueryResults = () => {
  const { themeHasFeature } = useMerchantTheme();
  const { LinkComponent } = useNavBarContext();
  const { setSearchState, handleCloseSearchOverlay, getSearchQueryUrl, trackSearchEvent } =
    useGlobalSearchContext();
  const { items, results } = useHits<SearchSearchTerm>();

  const currentQuery = useMemo(
    () => (results?.query && results.query !== '*' ? results.query : ''),
    [results],
  );

  const searchState = useMemo(() => {
    /** There is a query and there are results */
    if (currentQuery && items.length > 0) {
      return ProductsSearchResultsState.HAS_RESULTS;
    }
    /** There is a query but there are no results */
    if (currentQuery) {
      return ProductsSearchResultsState.NO_RESULTS;
    }
    /** There is a not a query and no results */
    return ProductsSearchResultsState.DEFAULT;
  }, [currentQuery, items]);

  /**
   * Looks a bit hacky, but we need to send the `searchState` to a parent component that's outside
   * of the `<InstantSearch />` provider. This is because we need to know if there are results or
   * not, so that we can track the search event and store the search term in the recent searches.
   */
  useEffect(() => {
    setSearchState(searchState);
  }, [setSearchState, searchState]);

  const handleClickSuggestion = (term: string) => {
    trackSearchEvent(term);
    handleCloseSearchOverlay();
  };

  const Link = LinkComponent || 'a';

  return (
    <div
      className={cn('flex flex-col px-6 pb-6', {
        'gap-8 pt-8': searchState !== ProductsSearchResultsState.NO_RESULTS,
        'gap-10 pt-10': searchState === ProductsSearchResultsState.NO_RESULTS,
      })}
      data-testid="GlobalSearchQueryResults"
    >
      {searchState === ProductsSearchResultsState.HAS_RESULTS && (
        <div className="flex flex-col gap-6" data-testid="GlobalSearchQueryResults-Suggestions">
          <Typography bold>Suggestions</Typography>
          {items.map((hit) => (
            <Button
              as={Link}
              key={hit.id}
              href={getSearchQueryUrl(hit.term)}
              variant="text"
              color="charcoal"
              startIcon={<IconSearch className="w-6 shrink-0" />}
              onClick={() => handleClickSuggestion(hit.term)}
              className="font-normal"
              data-testid={`GlobalSearchQueryResults-Suggestions-${hit.id}`}
            >
              <Highlight
                attribute="term"
                hit={hit}
                highlightedTagName="strong"
                classNames={{ highlighted: 'font-bold' }}
              />
            </Button>
          ))}
        </div>
      )}

      {searchState === ProductsSearchResultsState.NO_RESULTS && (
        <div className="flex flex-col gap-2" data-testid="GlobalSearchQueryResults-NoResults">
          <Typography bold>No Results found</Typography>
          <Typography>
            {`We are sorry but we can't find any results for `}
            <Typography bold element="span">{`“${currentQuery}”`}</Typography>
          </Typography>
        </div>
      )}

      {[ProductsSearchResultsState.DEFAULT, ProductsSearchResultsState.NO_RESULTS].includes(
        searchState,
      ) && (
        <div className="flex flex-col gap-8">
          <GlobalSearchTrending />
          <GlobalSearchRecentSearches />
          {themeHasFeature('search.overlay.default.make') && <GlobalSearchBrands />}
          {themeHasFeature('search.overlay.default.category') && <GlobalSearchCategories />}
        </div>
      )}
    </div>
  );
};
