import { useMemo } from 'react';
import { NavBarWrapper, type NavDataModel, cn, useMerchantTheme } from 'uibook';
import { Link } from '../../elements/Link/Link';
import { useRouter } from 'next/router';
import { consumerTypePathBase } from '@/types/consumerTypes';
import { getAlternateConsumerUrl } from '@/utils/urls.utils';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useIntercom } from 'react-use-intercom';
import { useRayloCookiesContext } from '@/hooks/useRayloCookiesContext';
import { track } from '@/integrations/segment/segmentTracking';
import { removeSessionStorage } from '@/utils/handleSessionStorage';

type GlobalNavBarProps = {
  navData: NavDataModel;
};

const GlobalNavBar = ({ navData }: GlobalNavBarProps) => {
  const { isRayloTheme } = useMerchantTheme();
  const { consumerType } = useConsumerTypeContext();
  const {
    isMobileApp,
    accountSwitcherData,
    hasLoggedInCustomer,
    activeAccount,
    overallAccountStatus,
    isUpgrading,
  } = useCustomerContext();
  const { shutdown } = useIntercom();
  const { setDomainCookie, removeDomainCookie } = useRayloCookiesContext();
  const router = useRouter();

  const loggedInData = useMemo(() => {
    if (hasLoggedInCustomer) {
      const onLogout = () => {
        if (window?.Intercom) {
          shutdown();
        }
        window.location.assign(`${process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}/logout`);
      };

      const addBusinessAccountOnClick = () => {
        setDomainCookie('checkoutContext', 'ADD_NEW_BUSINESS');
        router.push('/business/products');
      };

      return {
        onLogout,
        addBusinessAccountOnClick,
        activeAccount: activeAccount ?? accountSwitcherData?.accounts[0],
        name: accountSwitcherData?.customerName ?? '',
        email: accountSwitcherData?.email ?? '',
        accounts: accountSwitcherData?.accounts ?? [],
        defaultAccount: activeAccount ?? accountSwitcherData?.accounts[0],
      };
    }
    return null;
  }, [hasLoggedInCustomer, activeAccount, accountSwitcherData, router, setDomainCookie, shutdown]);

  /**
   * Don't render the NavBar if the user is on the recommendations page (in reality, users should
   * only see the recommendations page if they are logged in, but put this extra check here for
   * local dev purposes).
   */
  if (
    [
      '/recommendations',
      '/business/recommendations',
      '/recently-viewed',
      '/business/recently-viewed',
    ].includes(router.asPath)
  ) {
    return null;
  }

  const toggleUrl = getAlternateConsumerUrl(consumerType, router.asPath);

  if (isMobileApp) {
    return;
  }

  const hasCalloutCarousel = !loggedInData && isRayloTheme;

  return (
    <div
      className={cn('bg-tv-header relative', {
        'h-[160px] lg:h-[154px]': !hasCalloutCarousel,
        'h-[208px] sm:h-[188px] lg:h-[190px]': hasCalloutCarousel,
      })}
    >
      {/**
       * If the user is on a PDP and `router.isFallback` is `true`, this means that it's running ISR mode,
       * so we don't have access to the `navData` at this point. This means we can't render the
       * `<NavBarWrapper />` without causing any hydration errors. Instead, the parent div should act as a
       * placeholder whilst the page is being generated.
       *
       * Once the page is cached, any future visits to the page will have `router.isFallback` as `false`,
       * and the page will render as normal.
       */}
      {!router.isFallback && (
        <NavBarWrapper
          app="products"
          accountHomeUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
          productsUrl={consumerTypePathBase[consumerType]}
          navData={navData}
          LinkComponent={Link}
          consumerType={consumerType}
          toggleUrl={toggleUrl}
          loggedInData={loggedInData}
          overallAccountStatus={overallAccountStatus}
          isUpgrading={isUpgrading}
          removeDomainCookie={(key) =>
            removeDomainCookie(key as Parameters<typeof removeDomainCookie>[0])
          }
          removeSessionStorage={removeSessionStorage}
          searchClientConfig={{
            apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_KEY!,
            host: process.env.NEXT_PUBLIC_TYPESENSE_NODE!,
          }}
          router={{
            push: router.push,
          }}
          track={track}
        />
      )}
    </div>
  );
};

export default GlobalNavBar;
