import type { Account } from '../types/navData.types';

export const getActiveAccountSlug = (account: Account) => {
  if (!account.id) return null;

  if (!account.isBusiness) {
    return 'personal';
  }
  return account.name.replaceAll(' ', '-').toLowerCase();
};
