import { palette } from '../../../uibook/src/colors';
import { defineThemeVariables, type ThemePalette } from '../themes.types';

/** PlayStation Flex */
export const FLEX_PALETTE = {
  black: '#000000',
  orange: {
    500: '#CD3D0F',
    600: '#992E0B',
  },
  blue: {
    500: '#00439C',
  },
  sky: {
    300: '#E1F2FE',
    400: '#B2D4EF',
    500: '#0070CC',
    600: '#005499',
  },
  grey: {
    500: '#ADADAD',
    600: '#6D6D6D',
  },
} satisfies ThemePalette;

export const FLEX_VARIABLES = defineThemeVariables({
  primary: FLEX_PALETTE.sky[500],
  header: FLEX_PALETTE.black,
  'nav-divider': palette.white,
  'nav-link-hover': palette.charcoal[500],
  'breadcrumb-link-hover': FLEX_PALETTE.grey[500],
  'breadcrumb-link-disabled': FLEX_PALETTE.grey[500],
  'banner-button-with-icon': FLEX_PALETTE.blue[500],
  'icon-primary': FLEX_PALETTE.sky[500],
  'icon-bullet': FLEX_PALETTE.black,
  'checkbox-active': FLEX_PALETTE.sky[500],
  'checkbox-disabled': FLEX_PALETTE.sky[400],
  'radio-button-box-active': FLEX_PALETTE.sky[500],
  'progress-bar': FLEX_PALETTE.black,
  'alert-fill': palette.charcoal[100],
  'alert-border': palette.charcoal[200],
  'section-info-fill': palette.charcoal[100],
  'legacy-modal-close-fill': palette.white,
  'how-it-works-fill': palette.charcoal[100],
  'how-it-works-number': FLEX_PALETTE.sky[500],
  'how-it-works-icon': FLEX_PALETTE.sky[500],
  'pdp-header-fill': palette.charcoal[100],
  'header-benefits-fill': FLEX_PALETTE.sky[500],
  'header-benefits-fill-md': FLEX_PALETTE.sky[500],
  'header-benefits-icon-md': palette.white,
  'header-benefits-box-fill': FLEX_PALETTE.sky[500],
  'header-benefits-title': palette.white,
  'checkout-summary-fill': FLEX_PALETTE.black,
  'checkout-summary-scrollbar-thumb': FLEX_PALETTE.black,
  'checkout-summary-scrollbar-track': palette.white,
  'trustpilot-fill': palette.charcoal[100],
  'trustpilot-text': palette.charcoal[500],
  'trustpilot-bullets-icon': palette.charcoal[500],
  'company-search-button': FLEX_PALETTE.sky[500],
  'company-search-button-hover': FLEX_PALETTE.sky[600],
  'company-search-select-focus': FLEX_PALETTE.sky[400],
  'company-search-select-active': FLEX_PALETTE.sky[400],
  'checkout-header-fill': palette.white,
});

export const FLEX_FONTS = {
  'brand-alpha': [
    {
      weight: 300,
      filename: 'SSTPro-light',
    },
    {
      weight: 400,
      filename: 'SSTPro-roman',
    },
    {
      weight: 500,
      filename: 'SSTPro-medium',
    },
    {
      weight: 700,
      filename: 'SSTPro-bold',
    },
  ],
  'brand-beta': [
    {
      weight: 700,
      filename: 'Mont-Heavy',
    },
  ],
};
