import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconSynchronizeArrowClock = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.333 20.917a8.667 8.667 0 1 0-8.666-8.667v.667"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="m2 10.247 2.667 2.667 2.666-2.667m5.334-3.333v6h4.666"
    />
  </svg>
);
export const IconSynchronizeArrowClock = memo(SvgIconSynchronizeArrowClock);
