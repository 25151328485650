import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLogoGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 90 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.358 10.514h10.271s.186.74.186 2.02c0 6.067-4.388 10.17-10.382 10.17-6.815 0-11.444-5.537-11.444-11.215C.989 5.694 5.909.329 12.27.329c4.996 0 7.735 3.047 7.735 3.047l-2.134 2.209s-2.036-2.202-5.491-2.202c-4.48 0-8.051 3.55-8.051 8.16 0 4.732 3.747 8.108 8.041 8.108 3.357 0 6.912-1.821 7.272-6.092h-7.284zm11.45 4.927c0-3.84 3.02-7.133 7.235-7.133 3.465 0 7.222 2.453 7.222 7.216 0 4.156-3.23 7.167-7.172 7.167-4.355 0-7.285-3.354-7.285-7.25m11.314.064c0-2.698-1.964-4.372-4.037-4.372-2.329 0-4.118 1.86-4.118 4.351 0 2.546 1.812 4.384 4.109 4.384 2.09 0 4.046-1.722 4.046-4.363m11.657-7.197c-4.215 0-7.235 3.293-7.235 7.133 0 3.896 2.93 7.25 7.285 7.25C50.77 22.69 54 19.68 54 15.524c0-4.763-3.757-7.216-7.222-7.216Zm.042 2.825c2.073 0 4.036 1.674 4.036 4.372 0 2.64-1.955 4.363-4.046 4.363-2.297 0-4.109-1.838-4.109-4.384 0-2.49 1.79-4.35 4.119-4.35Zm8.481 4.369c0-3.8 3.041-7.186 6.91-7.186 1.684 0 2.953.652 3.88 1.65V8.749h3.017v12.923c0 5.077-2.931 7.497-6.777 7.497-3.517 0-5.492-2.162-6.473-4.235l2.766-1.155c.383.927 1.485 2.56 3.684 2.56 2.275 0 3.783-1.486 3.783-3.967v-1.418c-.805.934-1.896 1.748-3.947 1.748-3.317 0-6.843-2.87-6.843-7.2m11.034.015c0-2.763-1.948-4.382-3.844-4.382-2.03 0-4.024 1.648-4.024 4.409 0 2.657 1.92 4.33 3.982 4.33 1.942 0 3.886-1.548 3.886-4.357m16.19-7.218c-3.65 0-6.713 2.901-6.713 7.182 0 4.53 3.415 7.216 7.063 7.216 3.045 0 4.914-1.665 6.029-3.156l-2.488-1.654c-.645 1-1.725 1.98-3.526 1.98-2.023 0-2.953-1.108-3.53-2.18l9.65-4.001-.5-1.173c-.933-2.296-3.108-4.214-5.986-4.214Zm.125 2.766c1.315 0 2.261.698 2.663 1.536l-6.444 2.691c-.278-2.083 1.698-4.227 3.781-4.227m-8.269 11.204h-3.17V1.073h3.17z"
      clipRule="evenodd"
    />
  </svg>
);
export const IconLogoGoogle = memo(SvgIconLogoGoogle);
