import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLogoPlaystation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 42 42" {...props}>
    <path
      fill="currentColor"
      d="M38.894 29.472c-.727.918-2.505 1.573-2.505 1.573l-13.244 4.763v-3.514l9.745-3.478c1.108-.395 1.277-.957.377-1.25-.896-.297-2.52-.212-3.628.187l-6.492 2.29v-3.645l.372-.126s1.876-.666 4.516-.959c2.637-.29 5.87.04 8.406 1.003 2.857.904 3.179 2.238 2.453 3.156m-14.49-5.98v-8.981c0-1.055-.192-2.026-1.181-2.3-.756-.243-1.226.46-1.226 1.514v22.492l-6.06-1.926V7.474c2.577.478 6.33 1.611 8.347 2.29 5.132 1.764 6.872 3.96 6.872 8.906 0 4.82-2.973 6.648-6.75 4.822zM4.822 31.93c-2.934-.828-3.423-2.553-2.085-3.545 1.236-.917 3.337-1.607 3.337-1.607l8.687-3.094v3.527l-6.25 2.24c-1.104.396-1.275.957-.377 1.252s2.524.21 3.63-.187l2.997-1.088v3.153l-.596.101c-3 .49-6.195.286-9.343-.752"
    />
  </svg>
);
export const IconLogoPlaystation = memo(SvgIconLogoPlaystation);
