import { useHits } from 'react-instantsearch';
import { IconArrowRight } from 'uibook-icons/solid/IconArrowRight';
import { Typography } from '../../foundations/Typography/Typography';
import { ProductCard } from '../ProductCard/ProductCard';
import { formatDisplayPrice } from '../../utils/formatDisplayPrice';
import { getReactImageProps } from '../../utils/images';
import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { consumerTypeMoneyField } from '../../types/consumerTypes.types';
import { SearchProduct } from '../../types/search.types';
import { Button } from '../Button/Button';
import { useGlobalSearchContext } from './providers/GlobalSearchContext';
import { useMerchantTheme } from '../MerchantTheme/MerchantTheme';
import { useMemo } from 'react';

export const GlobalSearchProductCards = () => {
  const { themeHasFeature } = useMerchantTheme();
  const { LinkComponent, consumerType, consumerIsBusiness, productsUrl, loggedInData } =
    useNavBarContext();
  const { handleCloseSearchOverlay, getSearchQueryUrl, trackSearchEvent } =
    useGlobalSearchContext();
  const { items, results } = useHits<SearchProduct>();

  const Link = LinkComponent || 'a';

  const themeFeatureRenderCardsInDefaultState = themeHasFeature('search.overlay.default.products');

  const shouldRenderCardsInInitialState = useMemo(() => {
    /**
     * If `themeFeatureRenderCardsInDefaultState` is true, this component should be rendered when
     * the search overlay is initially opened
     */
    if (themeFeatureRenderCardsInDefaultState) {
      return true;
    }

    /**
     * Otherwise, if the component is in the initial state, then it should not be rendered. When
     * `query="*""`, that's the initial state.
     */
    return results?.query !== '*';
  }, [results?.query, themeFeatureRenderCardsInDefaultState]);

  if (!results?.query || !shouldRenderCardsInInitialState || items.length === 0) {
    return null;
  }

  const currentQuery = results.query;

  const priceField = consumerTypeMoneyField[consumerType];

  const handleClickSeeAll = () => {
    trackSearchEvent(currentQuery);
    handleCloseSearchOverlay();
  };

  return (
    <div
      className="bg-charcoal-100 flex flex-shrink-0 flex-grow flex-col gap-6 px-6 py-8"
      data-testid="GlobalSearchProductCards"
    >
      <Typography bold>Products</Typography>
      {items.map((item) => (
        <Link
          key={item.id}
          href={`${productsUrl}/products/${item.variantSlug}`}
          className="flex gap-4"
          onClick={handleCloseSearchOverlay}
          data-testid={`GlobalSearchProductCards-${item.id}`}
        >
          <ProductCard
            product={item}
            displayPrice={formatDisplayPrice(item.lowestMonthlyCost[priceField])}
            priceRenderState={loggedInData ? 'hidden' : 'displayed'}
            priceSuffix={consumerIsBusiness ? 'excl. VAT' : undefined}
            getImageProps={getReactImageProps}
          />
        </Link>
      ))}
      {/**
       * Only render this if there is a query, otherwise it'll look terrible, such as `See all “*”`, and
       * no-one wants that.
       */}
      {results.query !== '*' && (
        <div>
          <Button
            as={Link}
            variant="text"
            color="blue"
            size="large"
            href={getSearchQueryUrl(currentQuery)}
            onClick={handleClickSeeAll}
            endIcon={<IconArrowRight />}
            data-testid="GlobalSearchProductCards-SeeAll"
          >
            <span>
              {`See all “`}
              <strong className="font-bold">{currentQuery}</strong>
              {`”`}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};
