import { IconDevices } from 'uibook-icons/solid/IconDevices';
import { MediaObject } from '../MediaObject/MediaObject';
import { IconPlaystationFive } from 'uibook-icons/solid/IconPlaystationFive';
import { cn } from '../../utils/cn';
import { Button } from '../Button/Button';
import { Typography } from '../../foundations/Typography/Typography';
import RayloModal from '../../designSystem/RayloModal';
import { useState } from 'react';

type TrackingProps = {
  /** This is the function to call when the button is clicked to leave, used for tracking purposes */
  track: (eventName: string, properties: object) => void;
  /** This is the location of where the button is clicked to leave, used for tracking purposes */
  trackingLocation: 'header' | 'menu' | 'pdp' | 'checkout summary';
};

type PlayStationModalProps = TrackingProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * Thin wrapper around the `<MediaObject />`, which requires some extra styling props as it's not
 * currently aligned with the variants of the `<MediaObject />` component.
 */
export const MultipleDeviceBanner = (props: React.ComponentProps<typeof MediaObject>) => {
  return (
    <MediaObject
      className="bg-tv-section-info-fill border-0"
      media={<IconDevices className="text-tv-primary block w-full stroke-1" />}
      mediaWrapperProps={{ className: 'size-10' }}
      title="Need more than one device?"
      titleProps={{ className: 'mb-0.5' }}
      subtitle="After ordering your first device we’ll pre-approve you to order more devices"
      {...props}
    />
  );
};

export const PlayStationModal = ({ open, setOpen, trackingLocation, track }: PlayStationModalProps) => {
  if (!open) return null;

  const goToPlayStationStore = () => {
    track('Back to PSD', {
      type: trackingLocation,
    });
    /** Url params here are for playstation's own tracking purposes */
    window.location.assign('https://direct.playstation.com/en-gb?emcid=oth-ha-518949');
  };

  return (
    <RayloModal
      title="Leaving Flex?"
      copy={[
        "The Flex store offers the most flexible way to pay for your PlayStation with fixed monthly payments. If you'd prefer to buy upfront, you can head to PlayStation’s main store.",
        'Would you still like to leave?',
      ]}
      primaryButtonText="Stay on Flex"
      primaryButtonOnClick={() => setOpen(false)}
      secondaryButtonText="Go to PlayStation’s main store"
      secondaryButtonOnClick={goToPlayStationStore}
      handleCloseModal={() => setOpen(false)}
      modalOpen={open}
    />
  );
};

/**
 * Thin wrapper around the `<MediaObject />`, this component is used in both the products and
 * checkout apps, although it should only be used for the Flex theme.
 */
export const FlexLeasingBanner = (
  props: React.ComponentProps<typeof MediaObject> & TrackingProps,
) => {
  const { className, ...otherProps } = props;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <MediaObject
        className={cn('bg-tp-flex-sky-300 border-0', className)}
        variant="themed"
        media={<IconPlaystationFive className="text-tv-primary block w-full stroke-[0.75]" />}
        mediaWrapperProps={{ className: 'size-12' }}
        title="Leasing not for you?"
        body={
          <Typography variant="body2">
            Explore other options to buy{' '}
            <Button
              variant="text"
              onClick={() => setModalOpen(true)}
              title="Playstation"
              className="font-normal"
            >
              here
            </Button>
          </Typography>
        }
        {...otherProps}
      />
      <PlayStationModal 
        open={modalOpen} 
        setOpen={setModalOpen} 
        trackingLocation={props.trackingLocation} 
        track={props.track}
      />
    </>
  );
};
