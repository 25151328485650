import {
  getCookies,
  getCookie,
  setCookie,
  deleteCookie,
  OptionsType,
  TmpCookiesObj,
} from 'cookies-next';

/** Shared cookies to have `RAYLO_COOKIE_PREFIX` ('raylo_') prepended to them */
type RayloCookieName =
  | 'activeAccountId'
  | 'checkoutContext'
  | 'checkoutToken'
  | 'checkoutMode'
  | 'consumerType'
  | 'demoMode'
  | 'organizationId'
  | 'recentSearches'
  | 'recentlyViewedProducts'
  | 'subscriptionId'
  | 'trackingDenied'
  | 'tracking'
  | 'userToken'
  | 'userTokenExpiresAt';

const RAYLO_COOKIE_PREFIX = 'raylo_';

/** Helper types for use in `createServerDomainCookieMethod` and `createClientDomainCookieMethod` */
export type CookieMethod = typeof getCookie | typeof setCookie | typeof deleteCookie;
type UpdateFirstArgType<F> = F extends (x: never, ...args: infer P) => infer R
  ? (name: RayloCookieName, ...args: P) => R
  : never;
export type DomainCookieMethodParams<T extends CookieMethod> = Parameters<UpdateFirstArgType<T>>;
export type DomainCookieMethod<T extends CookieMethod> = (
  method: T,
  ...args: DomainCookieMethodParams<T>
) => ReturnType<T>;

/** Shared cookies with `RAYLO_COOKIE_PREFIX` ('raylo_') prepended to them */
export type RayloDomainCookieName = `${typeof RAYLO_COOKIE_PREFIX}${RayloCookieName}`;
export type RayloDomainCookieNamesList = Partial<Record<RayloDomainCookieName, string | undefined>>;

const defaultCookieOptions = {
  path: '/',
  /** @todo `process.env.REACT_APP_COOKIE_DOMAIN` can be removed once we deprecate create-react-app */
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? process.env.REACT_APP_COOKIE_DOMAIN,
  secure: true,
  sameSite: 'none',
} as const;

const getDomainCookieName = (name: RayloCookieName) => {
  return `${RAYLO_COOKIE_PREFIX}${name}` as RayloDomainCookieName;
};

/**
 * Filters all cookies to only include those that belong to the Raylo domain.
 *
 * @param allCookies - An object containing all cookies.
 * @returns An object containing only the cookies that start with the `RAYLO_COOKIE_PREFIX`.
 */
export const filterAllCookiesToDomainCookies = (allCookies?: TmpCookiesObj) => {
  if (!allCookies) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(allCookies).filter(([name]) => name.startsWith(RAYLO_COOKIE_PREFIX)),
  ) as RayloDomainCookieNamesList;
};

/**
 * Retrieves cookies for the current domain using the specified cookie store function.
 *
 * @template T - The type of the cookie store function, either `getCookiesClient` or
 *   `getCookiesServer`.
 * @param getCookiesStore - The function to retrieve cookies, either from the client or server.
 * @param options - Optional configuration for retrieving cookies.
 * @returns A promise that resolves to the filtered domain cookies if the cookie store function is
 *   asynchronous, or the filtered domain cookies directly if the cookie store function is
 *   synchronous.
 */
export const getDomainCookies = <T extends typeof getCookies>(
  getCookiesStore: T,
  options?: OptionsType,
) => {
  return getCookiesStore({
    ...defaultCookieOptions,
    ...options,
  }) as ReturnType<T>;
};

/**
 * Retrieves a domain-specific cookie using the provided cookie store function.
 *
 * @template T - The type of the cookie store function, either `getCookieClient` or
 *   `getCookieServer`.
 * @param getCookieStore - The function to retrieve the cookie, either `getCookieClient` or
 *   `getCookieServer`.
 * @param name - The name of the cookie to retrieve.
 * @param options - Optional settings to customize the cookie retrieval.
 * @returns The value of the cookie as returned by the provided cookie store function.
 */
export const getDomainCookie = <T extends typeof getCookie>(
  getCookieStore: T,
  name: RayloCookieName,
  options?: OptionsType,
) => {
  return getCookieStore(getDomainCookieName(name), {
    ...defaultCookieOptions,
    ...options,
  }) as ReturnType<T>;
};

/**
 * Sets a domain cookie using the provided cookie store function.
 *
 * @template T - The type of the cookie store function, either `setCookieClient` or
 *   `setCookieServer`.
 * @param {T} setCookieStore - The function to set the cookie, either `setCookieClient` or
 *   `setCookieServer`.
 * @param {RayloCookieName} name - The name of the cookie.
 * @param {string} value - The value to set for the cookie.
 * @param {OptionsType} [options] - Optional settings for the cookie.
 * @returns {ReturnType<T>} The result of the cookie store function.
 */
export const setDomainCookie = <T extends typeof setCookie>(
  setCookieStore: T,
  name: RayloCookieName,
  value: string,
  options?: OptionsType,
) => {
  return setCookieStore(getDomainCookieName(name), value, {
    ...defaultCookieOptions,
    ...options,
  }) as ReturnType<T>;
};

/**
 * Deletes a domain cookie using the provided cookie deletion function.
 *
 * @template T - The type of the cookie deletion function, either `deleteCookieClient` or
 *   `deleteCookieServer`.
 * @param deleteCookieStore - The function to delete the cookie, either `deleteCookieClient` or
 *   `deleteCookieServer`.
 * @param name - The name of the cookie to be deleted.
 * @param options - Optional settings for cookie deletion.
 * @returns The result of the cookie deletion function.
 */
export const deleteDomainCookie = <T extends typeof deleteCookie>(
  deleteCookieStore: T,
  name: RayloCookieName,
  options?: OptionsType,
) => {
  return deleteCookieStore(getDomainCookieName(name), {
    ...defaultCookieOptions,
    ...options,
  }) as ReturnType<T>;
};

/** @todo Add the other methods from `apps/frontend/src/utils/sharedCookies.ts` if needed */
