import { useNavBarContext } from '../navbar/NavBarContext/NavBarContext';
import { Typography } from '../../foundations/Typography/Typography';
import { useGlobalSearchContext } from './providers/GlobalSearchContext';
import { typesenseProductsFields } from '../../utils/search';
import { getCategoryIcon } from '../navbar/utils/utilComponents';

export const GlobalSearchCategories = () => {
  const { LinkComponent, productsUrl, navData } = useNavBarContext();
  const { handleCloseSearchOverlay } = useGlobalSearchContext();

  const Link = LinkComponent || 'a';

  const productsBaseUrl = `${productsUrl}/products`;

  return (
    <div className="-mx-6 flex flex-col gap-4" data-testid="GlobalSearchCategories">
      <Typography bold className="pl-6">
        Category
      </Typography>
      <div className="overflow-x-scroll [&::-webkit-scrollbar]:hidden">
        <div className="flex gap-6 px-6">
          {navData.categories.map((category) => (
            <Link
              key={category.name}
              href={`${productsBaseUrl}?${typesenseProductsFields.category}=${category.name.toLowerCase()}`}
              className="hocus:underline flex flex-col items-center gap-2.5 whitespace-nowrap"
              onClick={() => handleCloseSearchOverlay()}
              data-testid={`GlobalSearchCategories-${category.name}`}
            >
              <span className="bg-charcoal-100 flex size-16 items-center justify-center rounded-full">
                {getCategoryIcon(category.name.toLowerCase(), {
                  className: 'size-8 stroke-[1.25]',
                })}
              </span>
              <Typography variant="body2">{category.label ?? category.name}</Typography>
            </Link>
          ))}
          {/** Spacer element so that the right-hand side of the scroll area doesn't look terrible */}
          <span className="block w-2 flex-shrink-0" />
        </div>
      </div>
    </div>
  );
};
