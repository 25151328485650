import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLogoLenovo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 89 20" {...props}>
    <path
      fill="currentColor"
      d="M37.374 4.808c-1.59 0-3.4.737-4.506 2.214V5.039h-3.794v13.819h3.794v-7.863c0-1.415 1.097-2.92 3.13-2.92 1.57 0 3.194 1.093 3.194 2.92v7.863h3.794v-8.566c0-3.178-2.273-5.484-5.612-5.484m32.582.232-3.43 9.413-3.43-9.413H58.76l5.685 13.814h4.158L74.29 5.04zm-52.21 4.163c.646-.868 1.676-1.377 2.919-1.377 1.367 0 2.405.78 2.94 1.934l-6.629 2.75c-.18-1.412.18-2.516.77-3.307m7.29 5.407c-1.597 1.181-2.51 1.463-3.966 1.463-1.31 0-2.337-.407-3.06-1.12l9.701-4.024c-.212-1.5-.781-2.84-1.657-3.858-1.274-1.48-3.161-2.264-5.458-2.264-4.172 0-7.319 3.078-7.319 7.143 0 4.171 3.154 7.143 7.737 7.143 2.568 0 5.194-1.217 6.422-2.639zm-12.12.764H4.29V.907H.445v17.951h12.47zm68.188-7.315c-2.12 0-3.658 1.606-3.658 3.89 0 2.181 1.63 3.89 3.709 3.89 2.12 0 3.657-1.65 3.657-3.89 0-2.181-1.629-3.89-3.708-3.89m0 11.032c-4.15 0-7.402-3.092-7.402-7.142 0-4.005 3.274-7.142 7.453-7.142 4.15 0 7.4 3.092 7.4 7.142 0 4.004-3.273 7.142-7.451 7.142M51.889 8.059c-2.12 0-3.657 1.606-3.657 3.89 0 2.181 1.629 3.89 3.708 3.89 2.12 0 3.657-1.65 3.657-3.89 0-2.181-1.629-3.89-3.708-3.89m0 11.032c-4.15 0-7.401-3.092-7.401-7.142 0-4.005 3.273-7.142 7.452-7.142 4.15 0 7.401 3.092 7.401 7.142 0 4.004-3.274 7.142-7.452 7.142"
    />
  </svg>
);
export const IconLogoLenovo = memo(SvgIconLogoLenovo);
