import constate from 'constate';
import { useRayloCookiesContext } from './useRayloCookiesContext';
import { useQuery } from '@apollo/client';
import { useConsumerTypeContext } from './useConsumerTypeContext';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { isNativeAppWebView } from 'uibook/utils/isNativeAppWebView';
import { AccountStatusType, getAccountStatus, getActiveAccountSlug } from 'uibook';
import { GET_CUSTOMER_DATA } from '@/graphql/operations/getCustomerData.graphql';
import { getSessionStorage } from '@/utils/handleSessionStorage';
import { AuthTokenInfo } from 'core/auth/authTokenInfo';

const useCustomer = () => {
  const { consumerMoneyField } = useConsumerTypeContext();
  const router = useRouter();
  const { cookieValues, setDomainCookie } = useRayloCookiesContext();

  const {
    raylo_checkoutContext: cookieCheckoutContext,
    raylo_userToken: rayloUserToken,
    raylo_userTokenExpiresAt: rayloUserTokenExpiresAt,
  } = cookieValues;

  const hasAuthenticatedUser = useMemo(() => {
    const authToken = new AuthTokenInfo(rayloUserToken, rayloUserTokenExpiresAt);
    return authToken.isValid();
  }, [rayloUserToken, rayloUserTokenExpiresAt]);

  const checkoutContext = hasAuthenticatedUser ? cookieCheckoutContext : undefined;
  const subscriptionId = hasAuthenticatedUser ? getSessionStorage('subscriptionId') : undefined;

  const { data: customerData, loading: isCustomerDataLoading } = useQuery(GET_CUSTOMER_DATA, {
    skip: !hasAuthenticatedUser,
  });

  const matchingSubscription = useMemo(
    () =>
      customerData?.customer?.orders
        ?.flatMap((order) => order.items)
        .find(
          (item) =>
            item.subscription?.id === subscriptionId &&
            (item.subscription?.upgrade.eligible || item.subscription?.upgrade.checkout),
        )?.subscription,
    [customerData, subscriptionId],
  );

  const preApproval = customerData?.customer?.preApproval;
  const preApprovalCheckoutToken = preApproval?.checkout?.token;
  const upgradeCheckoutToken = matchingSubscription?.upgrade?.checkout?.token;

  // the upgrade token is used if the subscription ID exists, otherwise use the preapproval token
  const checkoutToken = subscriptionId ? upgradeCheckoutToken : preApprovalCheckoutToken;

  const isUpgrading = !!subscriptionId && !!matchingSubscription;
  const isAddingNewBusiness = checkoutContext === 'ADD_NEW_BUSINESS';
  const isMobileApp = isNativeAppWebView();

  /** Checks whether there are any orders in arrears or upgrade */
  const overallAccountStatus: AccountStatusType = useMemo(
    () =>
      customerData?.customer?.orders ? getAccountStatus(customerData?.customer?.orders) : null,
    [customerData?.customer?.orders],
  );

  /**
   * Due to how Next.js builds the server-generated content, and how the component tree is hydrated,
   * we should default the `hasLoggedInCustomer` and `inArrears` value to `false` to prevent a
   * hydration issue. Then, client-side, we can update the state to reflect the actual value, which
   * doesn't cause any hydration issues.
   */
  const [hasLoggedInCustomer, setHasLoggedInCustomer] = useState(false);
  const [inArrears, setInArrears] = useState(false);
  const [isAddingTech, setIsAddingTech] = useState(false);

  useEffect(() => {
    setHasLoggedInCustomer(hasAuthenticatedUser || isMobileApp);
  }, [hasAuthenticatedUser, isMobileApp]);

  useEffect(() => {
    setInArrears(hasAuthenticatedUser && overallAccountStatus === 'arrears');
  }, [hasAuthenticatedUser, overallAccountStatus]);

  useEffect(() => {
    setIsAddingTech(!isUpgrading && !isAddingNewBusiness && !inArrears);
  }, [setIsAddingTech, isUpgrading, isAddingNewBusiness, inArrears]);

  const accountSwitcherData = useMemo(() => {
    if (!customerData?.customer) {
      return null;
    }

    const accountsOnClick = (
      accountName: string,
      accountId: string,
      accountIsBusiness: boolean,
    ) => {
      if (!isAddingTech) {
        window.location.href = `${process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}/account/overview/${accountName}`;
        return;
      }

      setDomainCookie('activeAccountId', accountId);

      const slug = (() => {
        if (router.query?.product) {
          return `${accountIsBusiness ? '/business' : ''}/products/${router.query.product}?term=${router.query.term}`;
        } else {
          return `${accountIsBusiness ? '/business' : ''}/products`;
        }
      })();
      router.push(slug);
    };

    const organizationAccounts = customerData.customer.organizations.map((org) => {
      const orgOrders =
        customerData?.customer?.orders.filter((order) => order.organization?.id === org.id) || [];
      return {
        id: org.id,
        name: org.name,
        isBusiness: true,
        status: getAccountStatus(orgOrders),
        onClick: () => accountsOnClick(org.name.replaceAll(' ', '-').toLowerCase(), org.id, true),
      };
    });

    const name = `${customerData.customer.firstName} ${customerData.customer.lastName}`;

    const personalOrders =
      customerData?.customer?.orders.filter((order) => !order.organization) || [];

    const personalAccount = {
      name,
      id: customerData.customer?.id,
      isBusiness: false,
      status: getAccountStatus(personalOrders),
      onClick: () => accountsOnClick('personal', customerData?.customer?.id ?? '', false),
    };

    const hasPersonalOrders = customerData.customer.orders.some((order) => !order.organization);

    return {
      customerName: `${customerData?.customer?.firstName} ${customerData?.customer?.lastName}`,
      email: customerData.customer.email,
      accounts: [...(hasPersonalOrders ? [personalAccount] : []), ...organizationAccounts],
    };
  }, [customerData, setDomainCookie, isAddingTech, router]);

  const activeAccount = useMemo(
    () =>
      accountSwitcherData?.accounts.find(
        (account) => account.id === cookieValues?.raylo_activeAccountId,
      ),
    [accountSwitcherData, cookieValues],
  );

  const itemInArrearsPaymentSlug = useMemo(() => {
    if (inArrears) {
      const orderInArrears = customerData?.customer?.orders.find(
        (order) => (order?.items[0]?.subscription?.arrearsAmount?.valueInSubunit ?? 0) > 0,
      );
      const accountBaseUrl = process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL;
      return orderInArrears && activeAccount
        ? `${accountBaseUrl}/account/${getActiveAccountSlug(activeAccount)}/payment/${orderInArrears.items[0].subscription?.id}`
        : undefined;
    }
  }, [inArrears, activeAccount, customerData?.customer?.orders]);

  const formattedPreApprovedAmount =
    preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.formattedValue ?? null;
  const preApprovedAmount = preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.value ?? 0;

  if (!hasAuthenticatedUser) {
    return {
      isCustomerDataReady: true,
      isMobileApp,
      inArrears: false,
      isUpgrading: false,
      isAddingTech: false,
      isAddingNewBusiness: false,
      subscriptionId: undefined,
      hasLoggedInCustomer: false,
      hasAuthenticatedUser: false,
      checkoutToken: undefined,
      formattedPreApprovedAmount: null,
      preApprovedAmount: 0,
      accountSwitcherData: undefined,
      activeAccount: undefined,
      checkoutContext,
      deviceUpgrading: undefined,
      itemInArrearsPaymentSlug: undefined,
      overallAccountStatus: null,
    };
  }

  return {
    isCustomerDataReady: !hasAuthenticatedUser || isCustomerDataLoading === false,
    isMobileApp,
    isAddingTech,
    isUpgrading,
    isAddingNewBusiness,
    deviceUpgrading: matchingSubscription?.activeAsset?.variant?.product.displayName,
    inArrears,
    itemInArrearsPaymentSlug,
    subscriptionId,
    checkoutContext,
    hasLoggedInCustomer,
    checkoutToken,
    formattedPreApprovedAmount,
    preApprovedAmount,
    accountSwitcherData,
    activeAccount,
    overallAccountStatus,
  };
};

const [CustomerProvider, useCustomerContext] = constate(useCustomer);
export { CustomerProvider, useCustomerContext };
