'use client';

import { StyledNavBar, StyledNavBarWrapper } from './NavBarWrapper.styles';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import TrustPilotNavImg from '../svgs/trustpilot-navbar';
import { useState, useEffect, useRef, useMemo } from 'react';
import { SideNav } from '../SideNav/SideNav';
import { BurgerNav } from '../utils/utilComponents';
import {
  helpSubMenuLinks,
  categoryForUrl,
  aboutSubMenuLinks,
  NAVBAR_HEIGHTS,
  BREADCRUMBS_HEIGHT,
  NavbarHeightsBreakpoints,
} from '../utils/utils';
import { DropdownSubMenu } from '../DropdownSubMenu/DropdownSubMenu';
import CalloutCarousel from '../../CalloutCarousel/CalloutCarousel';
import { NavBarContextProvider, useNavBarContext } from '../NavBarContext/NavBarContext';
import { NavBarConsumerToggle } from '../NavBarConsumerToggle/NavBarConsumerToggle';
import { TAILWIND_BREAKPOINTS } from '../../../constants';
import { LoginPrompt } from '../LoginPrompt/LoginPrompt';
import { cn } from '../../../utils/cn';
import { DesktopLinks } from '../DesktopLinks/DesktopLinks';
import { GlobalSearch } from '../../GlobalSearch/GlobalSearch';
import { IconSearch } from 'uibook-icons/solid/IconSearch';
import { NavBarFakeSearchField } from '../NavBarFakeSearchField/NavBarFakeSearchField';
import { useMerchantTheme } from '../../MerchantTheme/MerchantTheme';
import { Theme } from 'uibook-themes';
import { handleUpgradeExitModalOpenProps } from '../Navbar.types';
import { IconLogoPlaystationText } from 'uibook-icons/custom/IconLogoPlaystationText';
import useMedia from 'use-media';
import { throttle } from '../../../utils/throttle';
import { PlayStationModal } from '../../MultipleDeviceBanner/MultipleDeviceBanner';

export const NavBarWrapper = (props: React.ComponentProps<typeof NavBarContextProvider>) => {
  return (
    <NavBarContextProvider {...props}>
      <NavBarWrapperWithContext />
    </NavBarContextProvider>
  );
};

const NavBarWrapperWithContext = () => {
  const { themeName, isRayloTheme } = useMerchantTheme();
  const {
    navData,
    accountHomeUrl,
    productsUrl,
    LinkComponent,
    sideNavOpen,
    setSideNavOpen,
    handleCloseSideNav,
    consumerIsBusiness,
    loggedInData,
    overallAccountStatus,
    app,
    isUpgrading,
    removeDomainCookie,
    removeSessionStorage,
    searchIsOpen,
    setSearchIsOpen,
    track,
  } = useNavBarContext();

  const navRef = useRef<HTMLDivElement>(null);
  const calloutRef = useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [calloutHeight, setCalloutHeight] = useState(0);
  const [navPositionIsFixed, setNavPositionIsFixed] = useState(false);
  const [showSearchCtaInHeader, setShowSearchCtaInHeader] = useState(false);
  const [playstationModalOpen, setPlaystationModalOpen] = useState(false);
  const oldScrollY = useRef(0);
  const SCROLL_THRESHOLD = 60;
  const [upgradeExitModalOpen, setUpgradeExitModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<handleUpgradeExitModalOpenProps | null>(
    null,
  );
  const [displayUpgradeModal, setDisplayUpgradeModal] = useState(false);

  // complaining that this needs to be in a useMemo - we cant do this as these are hooks
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const breakpoints: Record<NavbarHeightsBreakpoints, boolean> = {
    xs: useMedia({ maxWidth: TAILWIND_BREAKPOINTS.xs - 1 }),
    sm: useMedia({
      maxWidth: TAILWIND_BREAKPOINTS.lg - 1,
      minWidth: TAILWIND_BREAKPOINTS.xs,
    }),
    lg: useMedia({ minWidth: TAILWIND_BREAKPOINTS.lg }),
  };

  const hasCalloutCarousel = !loggedInData && isRayloTheme;

  const callouts = [
    <p key="callout-1">Raylo is authorised and regulated by the Financial Conduct Authority</p>,
    <p key="callout-2">As seen on TV</p>,
    <p key="callout-3">Join over 100,000 subscribers</p>,
  ];

  const navHeight = useMemo(() => {
    const navHeights = hasCalloutCarousel
      ? NAVBAR_HEIGHTS.withCalloutCarousel
      : NAVBAR_HEIGHTS.withoutCalloutCarousel;
    const screenSize = Object.entries(breakpoints).find(([, value]) => value)?.[0] as
      | NavbarHeightsBreakpoints
      | undefined;

    if (!screenSize) {
      return navHeights.xs;
    }

    return navHeights[screenSize];
  }, [breakpoints, hasCalloutCarousel]);

  /** Throttle the change of direction to stop the nav bouncing around when scrolling erratically */
  const throttledChangeDirection = throttle(setScrollDirection, 1000);

  useEffect(() => {
    if (!navRef.current) return;

    const handleScroll = () => {
      const thresholdToUse = app === 'products' ? navHeight + BREADCRUMBS_HEIGHT : SCROLL_THRESHOLD;

      if (hasCalloutCarousel && calloutRef.current) {
        setCalloutHeight(calloutRef.current.getBoundingClientRect().height);
      }

      if (window.scrollY <= calloutHeight) {
        setNavPositionIsFixed(false);
        return;
      }

      if (window.scrollY < thresholdToUse) {
        setScrollDirection('up');
        setShowSearchCtaInHeader(false);
        return;
      }
      if (window.scrollY > oldScrollY.current) {
        throttledChangeDirection('down');
      } else {
        throttledChangeDirection('up');
      }
      if (window.scrollY > thresholdToUse) {
        setShowSearchCtaInHeader(true);
        setNavPositionIsFixed(true);
      }
      oldScrollY.current = window.scrollY;
    };

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (!entry) return;

      if (entry.contentRect.width < TAILWIND_BREAKPOINTS.lg) {
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    });

    observer.observe(navRef.current);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [app, calloutHeight, navHeight, hasCalloutCarousel, throttledChangeDirection]);

  /** Update displayUpgradeModal state client-side to prevent hydration error */
  useEffect(() => {
    setDisplayUpgradeModal(!!isUpgrading && app === 'products');
  }, [isUpgrading, app]);

  const handleUpgradeExitModalOpen = (action: handleUpgradeExitModalOpenProps) => {
    setSelectedAction(action);
    setUpgradeExitModalOpen(true);
  };

  const exitUpgrade = () => {
    setUpgradeExitModalOpen(false);
    removeDomainCookie?.('subscriptionId'); // @todo: remove this line after a short time so we know old cookies were removed
    removeSessionStorage?.('subscriptionId');
    if (selectedAction?.category) {
      if (selectedAction.category === 'burger-nav') {
        setSideNavOpen('product');
        return;
      }
      if (selectedAction.category) {
        window.location.assign(
          `${productsUrl}${categoryForUrl(selectedAction.category?.toLowerCase())}`,
        );
      }
    } else if (selectedAction?.url) {
      window.location.assign(`${accountHomeUrl}${selectedAction.url}`);
    }

    setSelectedAction(null);
  };

  const handleClickSearchCta = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!searchIsOpen) {
      track('Search CTA Clicked', {});
    }
    setSearchIsOpen(!searchIsOpen);
  };

  return (
    <>
      <StyledNavBarWrapper
        data-testid="navbar-wrapper"
        className="bg-tv-header"
        $calloutHeight={calloutHeight}
        $hideNav={showSearchCtaInHeader && scrollDirection === 'down'}
        $fixNav={navPositionIsFixed}
        $navHeight={navHeight}
        ref={navRef}
      >
        {hasCalloutCarousel && <CalloutCarousel callouts={callouts} ref={calloutRef} />}
        <StyledNavBar className="flex w-full items-center px-4 py-6">
          {
            {
              [Theme.FLEX]: (
                <>
                  <button
                    onClick={() => setPlaystationModalOpen(true)}
                    title="Playstation"
                    className="flex flex-col justify-center text-white"
                  >
                    <IconLogoPlaystationText className="h-[26px] text-white lg:h-[42px]" />
                  </button>
                  <PlayStationModal
                    track={track}
                    trackingLocation="header"
                    open={playstationModalOpen}
                    setOpen={setPlaystationModalOpen}
                  />
                </>
              ),
              [Theme.RAYLO]: (
                <>
                  <AnimatedLogo />
                  {!loggedInData && <TrustPilotNavImg className="trustpilot-nav-img shrink-0" />}
                </>
              ),
            }[themeName]
          }

          <div className="mx-8 hidden w-full max-w-96 lg:block">
            <NavBarFakeSearchField onClick={handleClickSearchCta} />
          </div>

          <div className="nav-primary-buttons shrink-0">
            <div className="quick-links">
              <button
                onClick={handleClickSearchCta}
                aria-label="Open Search panel"
                className={cn(
                  'global-search-trigger rounded-full bg-transparent p-2 transition-opacity duration-300 hover:bg-blue-400 focus:bg-blue-400 lg:hidden',
                  {
                    'opacity-100': showSearchCtaInHeader,
                    'opacity-0': !showSearchCtaInHeader,
                  },
                )}
                data-testid="search-cta"
              >
                <IconSearch className="w-6 text-white" />
              </button>

              {isRayloTheme && !loggedInData && (
                <DropdownSubMenu
                  title="About"
                  items={aboutSubMenuLinks}
                  LinkComponent={LinkComponent}
                />
              )}
              <DropdownSubMenu title="Help" items={helpSubMenuLinks} />
              {loggedInData?.activeAccount && (
                <button
                  className="my-account rounded-full p-2 transition duration-300 hover:bg-blue-400 focus:bg-blue-300"
                  onClick={() => setSideNavOpen((prev) => (prev ? null : 'account'))}
                  data-testid="my-account-button"
                >
                  <div
                    className={cn(
                      'relative flex h-6 w-6 items-center justify-center rounded-full bg-white font-bold text-blue-500',
                      {
                        'after:absolute after:right-0 after:top-0 after:h-2 after:w-2 after:rounded-full after:border after:border-white':
                          !!overallAccountStatus,
                        'after:bg-error-500': overallAccountStatus === 'arrears',
                        'after:bg-success-500': overallAccountStatus === 'upgrade',
                      },
                    )}
                  >
                    {loggedInData.activeAccount.name[0]}
                  </div>
                </button>
              )}
              {isRayloTheme && !loggedInData && (
                <a
                  className="text-link"
                  href={`https://www.raylo.com/${consumerIsBusiness ? 'business' : ''}#how-it-works`}
                  title="How it works"
                >
                  How it works
                </a>
              )}
              {isRayloTheme && !loggedInData && <NavBarConsumerToggle location="topNav" />}

              {isRayloTheme && !loggedInData && <LoginPrompt dataTestId="login-prompt-nav" />}

              <BurgerNav
                dataTestId="burger-mobile"
                onClick={() =>
                  displayUpgradeModal
                    ? handleUpgradeExitModalOpen({ category: 'burger-nav' })
                    : setSideNavOpen('product')
                }
                sideNavOpen={sideNavOpen}
              />
            </div>
          </div>
        </StyledNavBar>
        <DesktopLinks
          navData={navData}
          LinkComponent={LinkComponent}
          setUpgradeExitModalOpen={setUpgradeExitModalOpen}
          handleCloseSideNav={handleCloseSideNav}
          upgradeExitModalOpen={upgradeExitModalOpen}
          sideNavOpen={sideNavOpen}
          exitUpgrade={exitUpgrade}
          handleUpgradeExitModalOpen={handleUpgradeExitModalOpen}
          displayUpgradeModal={displayUpgradeModal}
          setSideNavOpen={setSideNavOpen}
          productsUrl={productsUrl}
        />
      </StyledNavBarWrapper>

      <div
        className={cn('bg-tv-header absolute left-0 w-full px-4 lg:hidden', {
          'top-[140px] sm:top-[123px]': hasCalloutCarousel,
          'top-[88px] pb-6': !hasCalloutCarousel,
        })}
      >
        <NavBarFakeSearchField onClick={handleClickSearchCta} />
      </div>

      <SideNav
        isOpen={sideNavOpen === 'product'}
        onClose={handleCloseSideNav}
        navData={navData}
        setSideNavOpen={setSideNavOpen}
        accountHomeUrl={accountHomeUrl}
        productsUrl={productsUrl}
        isLoggedIn={!!loggedInData}
        LinkComponent={LinkComponent}
        desktopSide="left"
      />
      <SideNav
        desktopSide="right"
        isOpen={sideNavOpen === 'account'}
        onClose={handleCloseSideNav}
        handleUpgradeExitModalOpen={handleUpgradeExitModalOpen}
        displayUpgradeModal={displayUpgradeModal}
        setSideNavOpen={setSideNavOpen}
        accountHomeUrl={accountHomeUrl}
        productsUrl={productsUrl}
        LinkComponent={LinkComponent}
        loggedInData={loggedInData}
        overallAccountStatus={overallAccountStatus}
      />
      <GlobalSearch />
    </>
  );
};
