const tryParseJson = <T>(value: string) => {
  try {
    return [true, JSON.parse(value) as T] as const;
  } catch {
    return [false, undefined] as const;
  }
};

/**
 * Parses a JSON string from a cookie value. If the cookie value is not a valid JSON string, it
 * attempts to decode the cookie value and parse it as JSON. If both attempts fail, it returns a
 * default return value.
 *
 * A typescript generic must be passed when calling this function to specify the expected type of
 * the parsed JSON object, as this cannot be inferred from `JSON.parse()`.
 *
 * **Valid:**
 *
 * ```ts
 * const userId = parseJsonCookie<string>(cookieValue, '');
 * ```
 *
 * **Invalid:**
 *
 * ```ts
 * const userId = parseJsonCookie(cookieValue, '');
 * ```
 *
 * @template TReturnType - The expected type of the parsed JSON object.
 * @template TDefaultValueType - The type of the default return value, which extends T.
 * @param {string | undefined} cookieValue - The cookie value to parse.
 * @param {U} defaultReturnValue - The default value to return if parsing fails.
 * @returns {T} - The parsed JSON object or the default return value.
 */
export const parseJsonCookie = <
  TReturnType = never,
  TDefaultValueType extends TReturnType = TReturnType,
>(
  cookieValue: string | undefined,
  defaultReturnValue: TDefaultValueType,
): TReturnType => {
  if (!cookieValue) {
    return defaultReturnValue;
  }

  /** Check if `cookieValue` is already a valid JSON string */
  const [isValidJson, parsedJson] = tryParseJson<TDefaultValueType>(cookieValue);
  if (isValidJson) {
    return parsedJson;
  }

  /** If not, check that the `cookieValue` is an encoded string */
  const [isValidDecodedJson, parsedDecodedJson] = tryParseJson<TDefaultValueType>(
    decodeURIComponent(cookieValue),
  );
  if (isValidDecodedJson) {
    return parsedDecodedJson;
  }

  /** If not, return the default return value */

  return defaultReturnValue;
};
