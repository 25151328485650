import { sessionInfo as googleAnalytics4Session } from '../googleAnalytics4/tracking';
import { isNativeAppWebView } from 'uibook';

export const track = (eventName: string, properties: object): void => {
  const { clientId, sessionId } = googleAnalytics4Session();
  /**
   * @todo Ensure that flex production deployment has NEXT_PUBLIC_MERCHANT_DOMAIN env set. Review
   *   this in future to dynamically track other merchants.
   */
  const merchantDomain = process.env.NEXT_PUBLIC_MERCHANT_DOMAIN || '';
  const merchant = merchantDomain.includes('playstation') ? 'Sony Playstation Flex' : 'Raylo';

  window.analytics?.track(eventName, {
    ...properties,
    ga_client_id: clientId,
    ga_session_id: sessionId,
    native_mobile_client: isNativeAppWebView(),
    merchant,
  });
};
