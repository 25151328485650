import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconSmartwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 22h-4a1.334 1.334 0 0 1-1.333-1.333V18h6.666v2.667A1.334 1.334 0 0 1 14 22M10 2h4a1.333 1.333 0 0 1 1.333 1.333V6H8.667V3.333A1.333 1.333 0 0 1 10 2m5.333 4H8.667A2.667 2.667 0 0 0 6 8.667v6.666A2.667 2.667 0 0 0 8.667 18h6.666A2.667 2.667 0 0 0 18 15.333V8.667A2.667 2.667 0 0 0 15.333 6"
    />
  </svg>
);
export const IconSmartwatch = memo(SvgIconSmartwatch);
