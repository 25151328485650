'use client';

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  deleteClientDomainCookie,
  getClientDomainCookie,
  setClientDomainCookie,
} from 'core/cookies/cookies.client';
import { parseJsonCookie } from 'core/cookies/cookies.utils';
import { useNavBarContext } from '../../navbar/NavBarContext/NavBarContext';

export enum ProductsSearchResultsState {
  DEFAULT = 'DEFAULT',
  HAS_RESULTS = 'HAS_RESULTS',
  NO_RESULTS = 'NO_RESULTS',
}

export type GlobalSearchContextType = {
  searchState: ProductsSearchResultsState;
  setSearchState: Dispatch<SetStateAction<ProductsSearchResultsState>>;
  trackSearchEvent: (searchTerm: string) => void;
  handleCloseSearchOverlay: () => void;
  getSearchQueryUrl: (searchTerm: string) => string;
  recentSearches: Set<string>;
  addRecentSearch: (searchTerm: string) => void;
  clearRecentSearches: () => void;
};

export const initialGlobalSearchContextState: GlobalSearchContextType = {
  searchState: ProductsSearchResultsState.DEFAULT,
  setSearchState: () => {},
  trackSearchEvent: () => {},
  handleCloseSearchOverlay: () => {},
  getSearchQueryUrl: () => '',
  recentSearches: new Set(),
  addRecentSearch: () => {},
  clearRecentSearches: () => {},
};

export const GlobalSearchContext = createContext<GlobalSearchContextType>(
  initialGlobalSearchContextState,
);

const RECENT_SEARCHES_KEY = 'recentSearches';
const MAX_RECENT_SEARCHES_ITEMS = 3;

const getRecentSearchesFromStore = () => {
  const parsedSearches = parseJsonCookie<string[]>(getClientDomainCookie(RECENT_SEARCHES_KEY), []);
  return new Set(parsedSearches);
};

export const GlobalSearchContextProvider = ({ children }: PropsWithChildren) => {
  const { productsUrl, track, setSearchIsOpen } = useNavBarContext();

  const [searchState, setSearchState] = useState(initialGlobalSearchContextState.searchState);
  const [recentSearches, setRecentSearches] = useState(
    initialGlobalSearchContextState.recentSearches,
  );

  useEffect(() => {
    setRecentSearches(getRecentSearchesFromStore());
  }, []);

  const handleCloseSearchOverlay = useCallback(() => {
    setSearchIsOpen(false);
  }, [setSearchIsOpen]);

  const getSearchQueryUrl = useCallback(
    (searchTerm: string) => {
      return `${productsUrl}/products?q=${encodeURI(searchTerm)}`;
    },
    [productsUrl],
  );

  const trackSearchEvent = useCallback(
    (searchTerm: string) => {
      track('Products Searched', {
        query: searchTerm,
      });
    },
    [track],
  );

  const addRecentSearch = (searchTerm: string) => {
    const recentSearches = getRecentSearchesFromStore();
    /** Only update if the `searchTerm` is not already in the `recentSearches` */
    if (!recentSearches.has(searchTerm)) {
      /** Add the term to the start of the Set */
      const newRecentSearches = new Set([searchTerm, ...recentSearches]);

      /**
       * Ensure we only store the last `MAX_RECENT_SEARCHES_ITEMS` items, and delete any items from
       * the end of the Set until we have the right amount.
       */
      if (newRecentSearches.size > MAX_RECENT_SEARCHES_ITEMS) {
        [...newRecentSearches].slice(MAX_RECENT_SEARCHES_ITEMS).forEach((key) => {
          newRecentSearches.delete(key);
        });
      }
      /** Store the `newRecentSearches` in the state */
      setRecentSearches(newRecentSearches);
      /** Store the items in the `localStorage` */
      setClientDomainCookie(RECENT_SEARCHES_KEY, [...newRecentSearches]);
    }
  };

  const clearRecentSearches = () => {
    deleteClientDomainCookie(RECENT_SEARCHES_KEY);
    setRecentSearches(initialGlobalSearchContextState.recentSearches);
  };

  const value = useMemo(
    () => ({
      searchState,
      setSearchState,
      handleCloseSearchOverlay,
      getSearchQueryUrl,
      trackSearchEvent,
      recentSearches,
      addRecentSearch,
      clearRecentSearches,
    }),
    [getSearchQueryUrl, handleCloseSearchOverlay, recentSearches, searchState, trackSearchEvent],
  );

  return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>;
};

export const useGlobalSearchContext = () => {
  return useContext(GlobalSearchContext);
};
