import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLogoSamsung = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 90 14" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M49.257 6.54s-.856-.54-1.386-.748c0 0-1.639-.774-1.964-1.142 0 0-.639-.613-.265-1.325 0 0 .157-.466.832-.466 0 0 .879.049.879.785V4.68h3.15l-.006-1.528S50.739.65 46.787.54c0 0-3.109-.208-4 1.497 0 0-.35.38-.35 1.705V4.7s-.036 1.105.518 1.816c0 0 .313.467 1.084.981 0 0 1.566.859 2.506 1.35 0 0 .949.552.824 1.43 0 0-.077.901-.994.864 0 0-.837-.038-.837-.922V9.181H42.18v1.507s-.094 2.88 4.22 2.88c0 0 4.12.086 4.312-2.956V9.385s.146-1.864-1.456-2.845ZM33.131.899l-1.054 6.73h-.247L30.822.96h-5.261l-.264 12.19h3.116l.037-9.185h.245l1.651 9.182h3.277l1.631-9.178h.217l.076 9.18h3.131L38.341.898h-5.21Zm-18.544.05-2.072 12.185h3.353l1.228-9.447h.276l1.227 9.447h3.353L19.88.95zm70.37 5.423v1.797h.86v1.93c0 .943-.792.96-.792.96-.962 0-.926-.896-.926-.896V3.489c0-.687.854-.724.854-.724.82 0 .825.82.825.82V4.6h3.158c.103-1.981-.285-2.46-.285-2.46C87.868.347 84.905.445 84.905.445c-4.553 0-4.048 3.558-4.048 3.558v6.453c.098 3.337 4.585 2.998 4.66 2.995 1.996-.225 2.599-.871 2.599-.871.555-.42.697-1.001.697-1.001.16-.338.199-1.417.199-1.417v-3.79zM74.49 8.355h-.137L71.149.902h-3.834v12.247h3.12l-.187-7.452h.14l3.32 7.452h3.717V.901h-3.16zM59.7 10.098s.045 1.005-.87 1.005c0 0-.963.052-.963-.98l-.01-9.214h-3.422v9.14s-.351 3.46 4.443 3.46c0 0 4.166.05 4.166-3.3v-9.3H59.7zM8.063 6.54S7.208 6 6.677 5.792c0 0-1.638-.773-1.963-1.141 0 0-.639-.615-.264-1.325 0 0 .156-.466.83-.466 0 0 .88.049.88.784v1.038h3.15l-.005-1.529S9.545.65 5.595.541c0 0-.298-.02-.735.002 0 0-2.393.131-3.249 1.468l-.017.027s-.35.38-.35 1.705V4.7s-.036 1.104.519 1.816c0 0 .313.466 1.084.98 0 0 1.566.86 2.506 1.351 0 0 .95.55.824 1.43 0 0-.078.9-.994.864 0 0-.837-.038-.837-.922V9.181H.989v1.508s-.094 2.88 4.218 2.88c0 0 4.12.086 4.314-2.956V9.388c0-.001.144-1.867-1.458-2.848"
      clipRule="evenodd"
    />
  </svg>
);
export const IconLogoSamsung = memo(SvgIconLogoSamsung);
