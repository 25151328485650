import { forwardRef, type ForwardedRef } from 'react';
import { px2Rem } from '../../utils/px2Rem';
import type { Account } from '../../types/navData.types';
import { Typography } from '../../foundations/Typography/Typography';
import { cn } from '../../utils/cn';
import { IconCheckBold } from 'uibook-icons/solid/IconCheckBold';
import Badge from '../Badge';
import { CSS_FONT_WEIGHT } from '../../constants';

type AccountSwitcherDropdownProps = {
  dataTestId?: string;
  activeAccount: Account;
  accounts: Account[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  closeSideNav: () => void;
};

const AccountSwitcherDropdown = forwardRef(function AccountSwitcherDropdown(
  {
    dataTestId,
    accounts,
    activeAccount,
    isOpen,
    setIsOpen,
    closeSideNav,
  }: AccountSwitcherDropdownProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const changeAccount = (accountOnClick: () => void) => {
    setIsOpen(false);
    accountOnClick();
    closeSideNav();
  };

  const dropdownMaxHeight = (accounts.length + 1) * 100;
  return (
    <div
      ref={ref}
      data-testid={`${dataTestId}-accounts-menu`}
      className={cn(
        `shadow-6 absolute bottom-6 left-3 top-[calc(100%-24px)] z-50 block h-fit max-h-[${dropdownMaxHeight}px] w-[calc(100%-24px)] overflow-hidden bg-white transition-[max-height] duration-300 ease-in-out`,
        {
          'h-0 max-h-0': !isOpen,
        },
      )}
    >
      <Typography bold style={{ padding: px2Rem(24), margin: 0 }}>
        Accounts
      </Typography>
      <ul className="border-b-charcoal-200">
        {accounts.map((account, index) => {
          const isActive = account.id === activeAccount.id;
          const isUpgrade = account.status === 'upgrade';
          const inArrears = account.status === 'arrears';
          return (
            <button
              key={index}
              className={cn(
                'hover:bg-charcoal-100 flex h-fit w-full items-center justify-between p-4',
                {
                  'bg-blue-100 hover:bg-blue-100': isActive,
                },
              )}
              onClick={() => changeAccount(account.onClick)}
              data-testid={`${dataTestId}-account-${index}-${account.isBusiness ? 'business' : 'personal'}`}
            >
              <div className="flex items-center gap-3">
                <div
                  className={cn(
                    'bg-charcoal-100 text-charcoal-500 relative flex h-8 w-8 shrink-0 items-center justify-center rounded-full font-bold',
                    {
                      'after:absolute after:right-0 after:top-0 after:h-2 after:w-2 after:rounded-full after:border after:border-white':
                        !!account.status,
                      'after:bg-error-500': inArrears,
                      'after:bg-success-500': isUpgrade,
                      'bg-blue-500 text-white hover:bg-blue-500': isActive,
                    },
                  )}
                >
                  {account.name[0]}
                </div>
                <Typography
                  data-testid={`${dataTestId}-name${isActive ? '-active' : ''}`}
                  variant="body2"
                  medium
                  className="text-left"
                >
                  {account.name}
                </Typography>
                {account.isBusiness && (
                  <Badge
                    color="pink"
                    type="rounded"
                    textTransform="capitalize"
                    fontSize={12}
                    fontWeight={CSS_FONT_WEIGHT.vars.regular}
                  >
                    Business
                  </Badge>
                )}
              </div>
              {isActive && <IconCheckBold className="text-charcoal-500 ml-3 w-6 shrink-0" />}
            </button>
          );
        })}
      </ul>
    </div>
  );
});

export default AccountSwitcherDropdown;
