import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconPlaystationFive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M6.05 10.954c.3-4.9 0-7.204 0-7.204s3.5-3 9.5-3c0 0 2.5 12.5 2.5 22.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M15.879 2.538c1.69-.7 3.394-1.788 7.371-1.788 0 0-.67 2.104-2.102 10.895"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M18.047 23.25h4.603l-1.5-2.5v-12c0-2-.4-3.4-.8-4.3-.3-.7-1-1.2-1.8-1.2h-2.542m-1.2 17.883c.234.819-.351 1.637-1.17 1.871-.585.117-1.053 0-1.521-.35l-1.404-1.639h-5.85l-1.404 1.638c-.351.351-.936.585-1.521.351-.82-.234-1.287-1.053-1.17-1.872l1.17-5.03a2.39 2.39 0 0 1 2.34-1.873h7.137a2.39 2.39 0 0 1 2.34 1.873l1.053 5.03Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M10.245 18.09a.234.234 0 0 1 0-.467m0 .467a.234.234 0 1 0 0-.467m-4.68.467a.234.234 0 0 1 0-.467m0 .467a.234.234 0 0 0 0-.467"
    />
  </svg>
);
export const IconPlaystationFive = memo(SvgIconPlaystationFive);
