'use client';

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { NavDataModel, LoggedInData } from '../../../types/navData.types';
import { consumerTypePathBase, ConsumerTypesEnum } from '../../../types/consumerTypes.types';
import type { AccountStatusType } from '../Navbar.types';
import { type Experiments } from '../../../utils/experiments';

/**
 * Types which are used as props for the `<NavBarContextProvider />` but are not passed to the
 * `<NavBarContext.Provider />`, so cannot be used in the `useNavBarContext()` hook.
 */
type NavBarContextPropsNotPassedToHook = {
  /** If the NavBar is being rendered from the `navbar` app, `frontend` app or the `products` app */
  app: 'navbar' | 'products' | 'account';
};

type NavBarContextProps = NavBarContextPropsNotPassedToHook & {
  navData: NavDataModel;
  accountHomeUrl: string;
  productsUrl: string;
  /** Optional React Component to override `a` elements with another element, such as `next/link` */
  LinkComponent?: React.ElementType;
  consumerType: ConsumerTypesEnum;
  toggleUrl: string;
  loggedInData?: LoggedInData;
  overallAccountStatus?: AccountStatusType;
  isUpgrading?: boolean;
  removeDomainCookie?: (key: string) => void;
  removeSessionStorage?: (key: string) => void;
  /** Config for the Typesense Search Client */
  searchClientConfig: {
    apiKey: string;
    host: string;
  };
  /**
   * Basic router implementation so that we can use both Next.js's `next/router` methods and the
   * native JS `location`
   */
  router: {
    /** Client-side method for updating the URL */
    push: (url: string) => void;
  };
  /** Function for passing events to segment */
  track: (eventName: string, properties: object) => void;
  /**
   * Key/value object of experiments, where the key is the experiment key in GrowthBook and the
   * value is the value returned from Growthbook.
   *
   * Example:
   *
   * ```tsx
   * <NavBarWrapper
   *   experiments={{
   *     [EXPERIMENT_PLACEHOLDER]: true,
   *   }}
   * />;
   * ```
   */
  experiments?: Partial<Experiments>;
};

export type SideNavType = 'product' | 'account' | null;

export type NavBarContextType = Omit<
  NavBarContextProps,
  keyof NavBarContextPropsNotPassedToHook
> & {
  sideNavOpen: SideNavType;
  setSideNavOpen: Dispatch<SetStateAction<SideNavType>>;
  handleCloseSideNav: () => void;
  /** Shorthand for when the consumer is in personal mode */
  consumerIsPersonal: boolean;
  /** Shorthand for when the consumer is in business mode */
  consumerIsBusiness: boolean;
  /** Add a query string param to URLs if the `consumerType` is `BUSINESS` */
  suffixUrlsWithConsumerType: (url: string) => string;
  loggedInData?: LoggedInData;
  showConsumerToggle?: boolean;
  isUpgrading?: boolean;
  removeDomainCookie?: (key: string) => void;
  removeSessionStorage?: (key: string) => void;
  app?: 'navbar' | 'products' | 'account';
  searchIsOpen: boolean;
  setSearchIsOpen: Dispatch<SetStateAction<boolean>>;
  /**
   * Key/value object of experiments, where the key is the experiment key in GrowthBook and the
   * value is the value returned from Growthbook.
   */
  experiments: Exclude<NavBarContextProps['experiments'], undefined>;
};

export const initialNavBarContextState: NavBarContextType = {
  navData: {} as NavDataModel,
  accountHomeUrl: '',
  productsUrl: '',
  LinkComponent: undefined,
  consumerType: ConsumerTypesEnum.PERSONAL,
  consumerIsPersonal: true,
  consumerIsBusiness: false,
  toggleUrl: '',
  sideNavOpen: null,
  setSideNavOpen: () => {},
  handleCloseSideNav: () => {},
  suffixUrlsWithConsumerType: () => '',
  loggedInData: null,
  overallAccountStatus: null,
  isUpgrading: false,
  removeDomainCookie: () => {},
  removeSessionStorage: () => {},
  searchClientConfig: {
    apiKey: '',
    host: '',
  },
  searchIsOpen: false,
  setSearchIsOpen: () => {},
  router: {
    push: () => {},
  },
  track: () => {},
  experiments: {},
};

export const NavBarContext = createContext<NavBarContextType>(initialNavBarContextState);

export const NavBarContextProvider = ({
  app,
  navData,
  accountHomeUrl,
  productsUrl: initialProductsUrl,
  LinkComponent,
  consumerType: initialConsumerType,
  toggleUrl,
  searchClientConfig,
  router,
  children,
  loggedInData,
  overallAccountStatus,
  isUpgrading,
  removeDomainCookie,
  removeSessionStorage,
  track,
  experiments: initialExperiments,
}: PropsWithChildren<NavBarContextProps>) => {
  const [sideNavOpen, setSideNavOpen] = useState<SideNavType>(
    initialNavBarContextState.sideNavOpen,
  );
  const [searchIsOpen, setSearchIsOpen] = useState(initialNavBarContextState.searchIsOpen);

  /**
   * Apply any local overrides to the `consumerType` and `productsUrl` if the `app` is `navbar` or
   * `account`.
   */
  const { consumerType, productsUrl } = useMemo(() => {
    /**
     * Handle URLs which are on WebFlow, such as the "about" page, to update URLs in the NavBar so
     * that point to the business pages.
     *
     * - `/about` -> links point to personal consumer product pages
     * - `/about?consumerType=business` -> links point to business consumer product pages
     * - `/business` -> links point to business consumer product pages
     * - `/another-url` -> links point to personal consumer product pages
     */
    if (app === 'navbar' && typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      if (
        window.location.pathname.startsWith(consumerTypePathBase[ConsumerTypesEnum.BUSINESS]) ||
        searchParams.get('consumerType') === ConsumerTypesEnum.BUSINESS
      ) {
        return {
          consumerType: ConsumerTypesEnum.BUSINESS,
          productsUrl: `${initialProductsUrl}${consumerTypePathBase[ConsumerTypesEnum.BUSINESS]}`,
        };
      }
    }

    /** Handle business URLs on account to point to business pages */
    if (app === 'account' && initialConsumerType === ConsumerTypesEnum.BUSINESS) {
      return {
        consumerType: initialConsumerType,
        productsUrl: `${initialProductsUrl}${consumerTypePathBase[ConsumerTypesEnum.BUSINESS]}`,
      };
    }

    /** Otherwise on products, just return whatever has been passed as a prop */
    return {
      consumerType: initialConsumerType,
      productsUrl: initialProductsUrl,
    };
  }, [app, initialConsumerType, initialProductsUrl]);

  const handleCloseSideNav = useCallback(() => {
    setSideNavOpen(null);
  }, []);

  /**
   * If the `experiments` props is undefined, return an empty object so that `experiments` is not
   * `undefined`.
   */
  const experiments = useMemo(() => {
    return initialExperiments ?? {};
  }, [initialExperiments]);

  /** Suffixes URLs with the `consumerType` query parameter if the `consumerType` is `BUSINESS`. */
  const suffixUrlsWithConsumerType = useCallback(
    (url: string) => {
      /** Only apply when the `consumerType` is `BUSINESS` */
      if (consumerType === ConsumerTypesEnum.BUSINESS) {
        /**
         * Some URLs are not a full absolute URL, they're just the pathname onwards. If they start
         * with `/`, assume it's a relative URL
         */
        const isRelativeUrl = url.startsWith('/');
        /** Use a default origin which we can add here, then remove later (if it's a relative URL) */
        const defaultOrigin = 'https://www.raylo.com';
        /**
         * Create a URL, if the `url` being passed in is relative, then use `defaultOrigin` as the
         * `base`, so that `new URL()` does not throw an error.
         */
        const newUrl = new URL(url, isRelativeUrl ? defaultOrigin : undefined);

        /** Set `consumerType` in the search params */
        newUrl.searchParams.set('consumerType', ConsumerTypesEnum.BUSINESS);

        /** If it was a relative URL, remove the domain so it remains a relative URL */
        if (isRelativeUrl) {
          return newUrl.toString().replace(defaultOrigin, '');
        }
        /** Otherwise, return the absolute URL */
        return newUrl.toString();
      }

      /** If it's not a a Business consumerType, just return the `url` which was passed in */
      return url;
    },
    [consumerType],
  );

  const value = useMemo(
    () => ({
      navData,
      accountHomeUrl,
      productsUrl,
      LinkComponent,
      consumerType: consumerType,
      consumerIsBusiness: consumerType === ConsumerTypesEnum.BUSINESS,
      consumerIsPersonal: consumerType === ConsumerTypesEnum.PERSONAL,
      toggleUrl,
      sideNavOpen,
      setSideNavOpen,
      handleCloseSideNav,
      suffixUrlsWithConsumerType,
      loggedInData,
      overallAccountStatus,
      isUpgrading,
      removeDomainCookie,
      removeSessionStorage,
      app,
      searchClientConfig,
      searchIsOpen,
      setSearchIsOpen,
      router,
      track,
      experiments,
    }),
    [
      navData,
      accountHomeUrl,
      productsUrl,
      LinkComponent,
      consumerType,
      toggleUrl,
      sideNavOpen,
      handleCloseSideNav,
      suffixUrlsWithConsumerType,
      loggedInData,
      overallAccountStatus,
      isUpgrading,
      removeDomainCookie,
      removeSessionStorage,
      app,
      searchClientConfig,
      searchIsOpen,
      router,
      track,
      experiments,
    ],
  );

  return <NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>;
};

export const useNavBarContext = () => {
  return useContext(NavBarContext);
};
