import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIconLogoApple = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 42" {...props}>
    <path
      fill="currentColor"
      d="M29.422 22.154c-.018-3.325 1.49-5.834 4.542-7.682-1.708-2.437-4.288-3.778-7.694-4.04-3.226-.254-6.75 1.875-8.04 1.875-1.363 0-4.488-1.785-6.941-1.785C6.219 10.604.832 14.553.832 22.59q0 3.561 1.308 7.356c1.163 3.325 5.36 11.478 9.74 11.342 2.289-.054 3.906-1.621 6.886-1.621 2.889 0 4.388 1.621 6.94 1.621 4.416-.063 8.213-7.474 9.321-10.807-5.923-2.782-5.605-8.154-5.605-8.326ZM24.28 7.28c2.48-2.936 2.253-5.61 2.18-6.57-2.19.127-4.724 1.486-6.168 3.162-1.59 1.793-2.526 4.013-2.326 6.513 2.371.181 4.533-1.032 6.314-3.107Z"
    />
  </svg>
);
export const IconLogoApple = memo(SvgIconLogoApple);
